import React, { useMemo, useState } from 'react';
import { Container } from '@styled-components/globalStyles';
import { EligiblePackageInfo } from '@manageSubscription/Types';
import {
  containsPackage,
  ContentfulAssets,
  hasMultipleTrialVariant,
  hasOneTrialEligibleRestNonEligible,
  hasOnlyOneTrialEligibleVariant,
  isTrialEligibleVariant,
} from '@manageSubscription';
import { TrialPackagesContainer } from '@manageSubscription/Packages/DiscountPackage/styles';
import { DropdownSection, ExpandCollapseSection, Wrapper } from '@manageSubscription/Packages/PaidPackage/styles';
import { PackageTrial } from './PackageTrial';
import Image from '@lib-components/Image';
import { ContinueButton, ContinueButtonsWrapper, ProductSection } from '@manageSubscription/ManagePackages/styles';
import Products from '@manageSubscription/Products';
import { useFlowMachine } from '@lib-appRouter/RouterContext';
import { useSelector } from '@xstate/react';
import TrialOptionsDisplay from './TrialOptionsDisplay';

export interface TrialPackagesProps {
  trialPackages: EligiblePackageInfo[];
  make: string | undefined;
  locale: string;
  tenantId: string;
  location: string;
  shouldMatchTrialWithCCRequired: boolean;
  assets: ContentfulAssets;
  productsToHide: string[];
  hasMultipleTrials: boolean;
  selectedTrialPackages: EligiblePackageInfo[];
  setSelectedTrialPackages: (packages: EligiblePackageInfo[]) => void;
  addAllPackages: (packages: EligiblePackageInfo[]) => void;
  handleNoThanks: () => void;
}

const TrialPackages = ({
  trialPackages = [],
  make = '',
  locale,
  tenantId,
  location,
  assets,
  productsToHide,
  hasMultipleTrials,
  selectedTrialPackages,
  setSelectedTrialPackages,
  addAllPackages,
  handleNoThanks,
}: TrialPackagesProps) => {
  const flowMachine = useFlowMachine();
  const {
    commonWebContent: { continueButtonLabel, skipAndContinueButtonLabel },
  } = useSelector(flowMachine, (state) => state.context.content);

  const [click, setClick] = useState(-1);

  const sortedPackages = useMemo(() => {
    return trialPackages?.sort((p1, p2) => p2.basePriceMonthly - p1.basePriceMonthly);
  }, [trialPackages]);

  if (!trialPackages.length) {
    return null;
  }

  const hasAMultipleTrialVariant = hasMultipleTrialVariant(trialPackages);
  const hasOneTrialEligibleVariant = hasOnlyOneTrialEligibleVariant(trialPackages);
  const hasOneTrialEligibleAndRestNonEligible = hasOneTrialEligibleRestNonEligible(trialPackages);

  const onProductInfoToggle = (index: number) => {
    return setClick(click === index ? -1 : index);
  };

  const handleTrialPackagesSelection = (packages: EligiblePackageInfo[]) => {
    setSelectedTrialPackages(packages);
  };

  const handleContinue = (selected: EligiblePackageInfo[]) => {
    const packagesToAdd = selected?.length
      ? selected
      : hasOneTrialEligibleAndRestNonEligible
      ? trialPackages.filter((pkg) => !isTrialEligibleVariant(pkg.variant))
      : trialPackages;

    addAllPackages(packagesToAdd);
  };

  const determineVariantInfoToShow = (selectedPackages: EligiblePackageInfo[], currentPackage: EligiblePackageInfo) => {
    const selected = selectedPackages?.find((p) => p.id === currentPackage.id);
    return selected?.variant || currentPackage.variant;
  };

  /**
   * This is to cover below scenario without disturbing other scenarios:
   * Eligible for 6M (ccRequired) and 12M (non-ccRequired)
   * Initially should show only 12M non-ccRequired on top section, on selection it should show both packages
   */
  const determinePackageToShow = (pkg: EligiblePackageInfo, selectedTrialPackages: EligiblePackageInfo[]) => {
    const containsTrialPackage = containsPackage(selectedTrialPackages, pkg);
    return !hasOneTrialEligibleAndRestNonEligible || !isTrialEligibleVariant(pkg.variant) || containsTrialPackage;
  };

  return (
    <Container>
      {sortedPackages.map((trialPackage, index: number) => {
        const { marketingName, shortDescription, products } = trialPackage;
        const isActive = hasMultipleTrials ? click === index : true;
        const showPackage = determinePackageToShow(trialPackage, selectedTrialPackages);
        return (
          <>
            {showPackage ? (
              <TrialPackagesContainer key={marketingName}>
                <Wrapper showBottomBorder={!isActive}>
                  <PackageTrial
                    marketingName={marketingName}
                    shortDescription={shortDescription}
                    variant={determineVariantInfoToShow(selectedTrialPackages, trialPackage)}
                  />
                  {hasMultipleTrials && (
                    <ExpandCollapseSection onClick={() => onProductInfoToggle(index)}>
                      <Image assets={assets} name={isActive ? 'ArrowUp' : 'ArrowDown'} />
                    </ExpandCollapseSection>
                  )}
                </Wrapper>

                {isActive && (
                  <DropdownSection showTopBorder={!hasMultipleTrials} showBottomBorder={hasMultipleTrials}>
                    <ProductSection>
                      <Products
                        products={products}
                        productsToHide={productsToHide}
                        assets={assets}
                        make={make}
                        locale={locale}
                        tenantId={tenantId}
                        location={location}
                      />
                    </ProductSection>
                  </DropdownSection>
                )}
              </TrialPackagesContainer>
            ) : (
              <></>
            )}
          </>
        );
      })}
      {(hasAMultipleTrialVariant || hasOneTrialEligibleAndRestNonEligible) && (
        <TrialOptionsDisplay
          trialPackages={trialPackages}
          selectedTrialPackages={selectedTrialPackages}
          onSelect={handleTrialPackagesSelection}
        />
      )}
      <ContinueButtonsWrapper>
        <ContinueButton
          fullWidth
          disabled={hasAMultipleTrialVariant && !selectedTrialPackages?.length}
          onClick={() => handleContinue(selectedTrialPackages)}
        >
          {continueButtonLabel}
        </ContinueButton>
        {hasOneTrialEligibleVariant && (
          <ContinueButton variant="outlined" onClick={handleNoThanks}>
            {skipAndContinueButtonLabel}
          </ContinueButton>
        )}
      </ContinueButtonsWrapper>
    </Container>
  );
};

export default TrialPackages;
