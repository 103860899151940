import React, { useEffect, useState } from 'react';
import Loader from '@components/Loader';
import { useHistory, useLocation } from 'react-router-dom';
import urlSearchParams from '@utils/urlSearchParams';
import { isEmpty } from 'lodash';
import { getLanguage } from '@utils/getLanguage';

export const SUBSCRIPTION_PAGE = 'manage';
export const SUBSCRIPTION_PAGE_URL = `/${SUBSCRIPTION_PAGE}`;

export const flowNames = [
  'enroll',
  'learnmore',
  'trial',
  'activate',
  'promocode',
  'savemore',
  'subscribe',
  'completeprofile',
];

const UrlParser: React.FC = ({ children }) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const [loading, setLoading] = useState(true);
  const [flowsParams, paramsToKeep] = urlSearchParams.getFlowParams();

  useEffect(() => {
    const [params, restParams] = urlSearchParams.extractSearchParams(search, flowsParams, paramsToKeep);
    const flowName = pathname.slice(1);
    urlSearchParams.set('page', flowName);

    if (flowNames.includes(flowName)) {
      urlSearchParams.setMultiple({
        flowName,
        uri: SUBSCRIPTION_PAGE_URL,
      });
      // we should not set pathname to one of the main login links
    } else if (!['/loginCallBack', '/login', '/authenticate'].includes(pathname)) {
      urlSearchParams.set('uri', pathname);
    }

    if (isEmpty(params)) {
      setLoading(false);
      return;
    }

    if (params.lang) {
      params.lang = getLanguage(params.lang) ?? '';
    }

    urlSearchParams.setMultiple(params);

    history.replace({
      pathname: '/authenticate',
      search: restParams ? `?${restParams}` : '',
    });

    setLoading(false);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return <>{children}</>;
};

export default UrlParser;
