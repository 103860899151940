import React, { useMemo } from 'react';
import ThemeProvider, { CustomTheme } from '@components-lib/styled-components/ThemeProvider';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ContentfulFile } from '@customTypes/ContentfulComponent';

type GlobalThemeFont = {
  family: string;
  src: GlobalThemeFontFile[];
  key?: string;
  style?: string;
  weight?: string;
};

type GlobalThemeFontFile = {
  format: string;
  file: ContentfulFile;
};

const loadFont = (font: GlobalThemeFont, sourceIndex = 0): Promise<FontFace> => {
  const source = font.src[sourceIndex];
  if (!source) {
    return Promise.reject(new Error('Font source file is missing'));
  }
  const customFont = new FontFace(font.family.trim(), `url(${source.file.file.url})`, {
    style: font.style || 'normal',
    weight: font.weight || 'normal',
  });
  return customFont
    .load()
    .then((fontFace) => {
      document.fonts.add(fontFace);
      return fontFace;
    })
    .catch(() => loadFont(font, sourceIndex++));
};

const ContentfulToMuiTheme = ({ contentfulThemes = [], children = null }: { children: React.Node }) => {
  const defaultTheme: CustomTheme = {
    primaryColor: '#209fec',
    primaryHoverColor: '',
    headerBackgroundColor: '#fff',
    subHeaderBackgroundColor: '#000',
    buttonRadius: 5,
    buttonBorderWidth: 1,
    secondaryColor: '#F1FAEE',
    backgroundColor: '#fff',
    errorColor: '#FF0000',
    navigationTextColor: '#000',
    textColor: '#000',
    cardBackgroundColor: '#fff',
    footerTextColor: '#000',
    footerBackgroundColor: '#fff',
  };
  const composedTheme = contentfulThemes.reduce((acc, theme) => {
    return { ...acc, ...theme };
  }, defaultTheme);

  useMemo(() => {
    contentfulThemes.forEach((theme) => {
      const { fonts = [] } = theme;

      // Load fonts
      fonts.forEach((font) => {
        loadFont(font)
          .then(() => {
            if (font.key) {
              document.documentElement.style.setProperty(font.key.trim(), `"${font.family.trim()}"`);
            }
          })
          .catch(() => {
            /* Do nothing */
          });
      });
    });
  }, [contentfulThemes]);

  return (
    <ThemeProvider theme={composedTheme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        {children}
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default ContentfulToMuiTheme;
