import React from 'react';
import { H1 } from '../../styled-components/globalStyles';
import Button from '../../components/Button';
import {
  BackgroundImage,
  FooterContainer,
  HeaderContainer,
  ColumnContainer,
  ServiceContainer,
  LogoContainer,
} from './InformationPageStyles';
import { Carousel, ContentfulFile, InformationPageContent, SubscriptionProps } from '../Subscription';

interface InformationPageEnrollmentProps {
  navigateForward: () => void;
  bgImageData: ContentfulFile;
  headerLogoData: ContentfulFile;
  carouselList: Carousel[];
  subscriptionTerms: string;
  informationPageEnrollmentContent: InformationPageContent;
  subscriptionProps?: SubscriptionProps;
}

const InformationPageEnrollment: React.FC<InformationPageEnrollmentProps> = ({
  navigateForward,
  bgImageData,
  headerLogoData,
  carouselList,
  subscriptionTerms,
  informationPageEnrollmentContent: {
    header = '',
    subHeader = '',
    headerFooter = '',
    bodyFooterTitle = '',
    bodyFooterSubtitle = '',
    continueButtonLabel = '',
  },
  subscriptionProps,
}) => {
  const url = bgImageData?.file.url;
  const { width, height } = bgImageData?.file.details?.image;
  const ContinueButton = () => (
    <Button fullWidth onClick={navigateForward} data-testid="continue">
      {continueButtonLabel}
    </Button>
  );
  return (
    <ColumnContainer>
      <BackgroundImage img={url} initialWidth={width} initialHeight={height}>
        <HeaderContainer>
          {headerLogoData && (
            <LogoContainer>
              <img src={headerLogoData.file.url} />
            </LogoContainer>
          )}
          <H1>{header}</H1>
          <p>{subHeader.replace('{{trialPeriod}}', subscriptionTerms)}</p>
          {ContinueButton()}
          <footer>{headerFooter}</footer>
        </HeaderContainer>
      </BackgroundImage>

      {carouselList.map((item) => {
        const { name, title, subtitle, component } = item;

        return (
          <ServiceContainer key={name as string}>
            {title && <h2>{title}</h2>}
            {subtitle && <p>{subtitle}</p>}

            {subscriptionProps.renderContentfulComponent({
              name: 'portalCarousel',
              content: [component],
              props: { showArrows: true },
            })}
          </ServiceContainer>
        );
      })}
      <FooterContainer>
        {bodyFooterTitle && <h2>{bodyFooterTitle}</h2>}
        {bodyFooterSubtitle && <p>{bodyFooterSubtitle.replace('{{trialPeriod}}', subscriptionTerms)}</p>}
        {ContinueButton()}
        <span>{headerFooter}</span>
      </FooterContainer>
    </ColumnContainer>
  );
};

export default InformationPageEnrollment;
