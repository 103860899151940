import { createGlobalStyle } from 'styled-components';
import { styled } from '@mui/material';
import Button from '../components/Button';

const size = {
  xs: '600px',
  sm: '768px',
  md: '992px',
  lg: '1200px',
  heightSm: '520px',
};

export const device = {
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  heightSm: `(min-height: ${size.heightSm})`,
};

export const breakpoint = { device };

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const Link = styled('a')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0.6rem;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
`;

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
`;

export const H1 = styled('h1')`
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 0.6rem;
  margin-top: 0.6rem;
`;

export const Heading = styled('h1')`
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

export const Title = styled('div')`
  font-weight: 700;
  font-size: 0.9375rem;
`;

export const SubTitle = styled('div')`
  font-weight: 400;
  font-size: 0.9375rem;
`;

export const Content = styled('span')`
  font-size: 0.9375rem;
`;

export const ContentCenter = styled(Content)`
  text-align: center;
  margin: 2rem 0 1rem 0;
`;

export const LineSeparator = styled('div')`
  border-bottom: 1px solid darkgrey;
  margin: 0.2rem 0;
`;

export const LineBorder = styled('div')`
  border-bottom: 2px solid #c1c1c1;
  margin: 0.25rem 0 0.4rem 0;
`;

export const LineBreak = styled('div')`
  line-break: strict;
`;

export const HSpace = styled('span')`
  margin-right: 0.3rem;
`;

export const VSpace = styled('span')`
  margin-top: 0.3rem;
  margin-bottom: 0.6rem;
`;

export const Card = styled('div')`
  padding: 2.25rem 0;
  @media screen and (max-width: 425px) {
    padding: 1.25rem;
  }
`;

export const CardAction = styled('div')`
  min-height: 3rem;
`;

export const IconStack = styled('span')`
  display: grid;
  align-items: center;
  justify-items: center;

  svg {
    grid-area: 1 / 1;
  }
`;

export const ContinueButton = styled(Button)`
  font-size: 0.9375rem;
  font-weight: 700;
  height: 50px;
  margin: 3rem 0 1rem 0;
  width: auto;

  @media only screen and ${breakpoint.device.md} {
    align-self: flex-end;
    min-width: 235px;
  }
`;

export const IconSection = styled('span')`
  display: flex;
  justify-content: center;
  margin: 1.3rem;
`;

export default GlobalStyle;
