import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getVehicleById } from '@cv/portal-cps-lib/vehicle';
import { VehicleRequest } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import { getCookie } from '@utils/cookies';
import { TokenTypes } from '@api/services/StoreService';
import config from '@config/config';
import { formatVehicleDetails } from '@api/formatters';
import { VehicleDetails } from '@api/types';
import { OwnershipType } from '@cv/portal-cps-lib/vehicle/vehicle-management/enums';
import { RootState } from '@app/reducers';
import { milliseconds } from 'date-fns';

function useVehicle(): UseQueryResult<VehicleDetails> {
  const selectedVehicleId = useSelector(({ vehicleReducer }: RootState) => vehicleReducer.vehicle?.vehicleId || '');
  const locale = useSelector(({ settingsReducer }) => settingsReducer?.locale);
  const tenantId = useSelector(({ accountReducer }) => accountReducer?.tenantId);
  const subscribedServices = useSelector(({ userReducer }) => userReducer?.subscribedServices);
  const environmentEnum = config.getEnvironmentEnum();
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';

  return useQuery({
    queryKey: ['vehicle', selectedVehicleId],
    queryFn: async () => {
      const payload: VehicleRequest = {
        data: {
          id: selectedVehicleId,
        },
        queryParams: {
          include: 'vehicle_services',
        },
        environment: environmentEnum,
        headers: {
          Authorization: accessToken,
          'Accept-Language': locale,
          'CV-Tenant-Id': tenantId,
        },
      };

      const response = await getVehicleById(payload);

      return response?.data;
    },
    refetchInterval: milliseconds({minutes: 5}),
    select: (_data: VehicleDetails) => formatVehicleDetails(_data, subscribedServices),
    enabled: Boolean(accessToken && selectedVehicleId),
    placeholderData: {
      active: false,
      activeServices: [],
      additionalFeatures: {},
      allVehicleServiceDetails: {
        vehicleId: '',
        vin: '',
        isDeviceLinked: false,
        vehicleServiceDetails: [],
      },
      color: '',
      demoVehicle: true,
      deviceLinks: [],
      engineType: '',
      externalVehicleIdentifier: '',
      make: '',
      manufactureDate: '',
      marketingColor: '',
      marketingServiceNames: [],
      model: '',
      nickname: '',
      originalSalesDate: '',
      ownershipType: OwnershipType.New,
      provisionable: false,
      registrationCountry: '',
      services: [],
      telematicsProgramId: '',
      tenantId: '',
      testVehicle: true,
      transmissionType: '',
      trialEligibilityStartDate: '',
      trimLevel: '',
      type: '',
      vehicleId: '',
      vehicleServiceDetails: [],
      vin: '',
      year: '',
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
  });
}

export default useVehicle;
