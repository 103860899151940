import ContentfulComponent from '@customTypes/ContentfulComponent';
import { AssetObject, ServerAsset, generateAssetsObjects } from '@utils/assets';
import { ServerLabel } from '@utils/labels';
import { CANCELLATION_LABELS_DICT_KEY, SERVICES_DESCRIPTIONS_DICT_KEY, SERVICES_HIERARCHY_DICT_KEY } from './constants';

export const getLabels = (contentSections: ContentfulComponent[]): ServerLabel[] => {
  const cancelLabels = contentSections.find((section) => section.dictionaryKey === CANCELLATION_LABELS_DICT_KEY);
  return cancelLabels?.content || [];
};

export type ServiceDescriptions = {
  [key: string]: {
    longDescription: string;
    shortDescription: string;
  };
};

export const getDescriptions = (contentSections: ContentfulComponent[]): ServiceDescriptions => {
  const { content } = contentSections.find((section) => section.dictionaryKey === SERVICES_DESCRIPTIONS_DICT_KEY) || {
    content: [],
  };

  return content.reduce<ServiceDescriptions>(
    (allDescriptions, { labelKey, labelValue, additionalLabelValue }) => ({
      ...allDescriptions,
      [labelKey]: { longDescription: labelValue, shortDescription: additionalLabelValue },
    }),
    {},
  );
};

export const getHierarchy = (contentSections: ContentfulComponent[]): string[] => {
  const hierarchyLabels = contentSections.find((section) => section.dictionaryKey === SERVICES_HIERARCHY_DICT_KEY);
  return ((hierarchyLabels?.content || []) as ServerLabel[]).map((l) => l.labelKey);
};

export type CancelationContentSection = ContentfulComponent & { assets?: ServerAsset[] };

export const getAssets = (contentSections: CancelationContentSection[]): AssetObject => {
  const subscription = contentSections.find((section) => Array.isArray(section.assets));

  if (subscription !== undefined) {
    return generateAssetsObjects(subscription.assets);
  }

  return {};
};
