import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import { isPaidPackage } from '@components-lib';

export const isPackageUpcoming = (packageItem: SubscribedPackage) =>
  !packageItem.active || new Date(packageItem.termStartDate).getTime() > Date.now();

const comparePackages = (pkgA: SubscribedPackage, pkgB: SubscribedPackage): number => {
  return (
    +isPackageUpcoming(pkgA) - +isPackageUpcoming(pkgB) ||
    isPaidPackage(pkgA) - isPaidPackage(pkgB) ||
    (isPaidPackage(pkgA)
      ? pkgA.amountWithoutTax - pkgB.amountWithoutTax
      : new Date(pkgA.termEndDate).getTime() - new Date(pkgB.termEndDate).getTime())
  );
};

export const orderPackages = <T extends SubscribedPackage>(packages: T[]): T[] => {
  const orderedPackages = packages;
  return orderedPackages.sort(comparePackages);
};
