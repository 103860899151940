import React from 'react';
import {
  DropdownSection,
  IconSection,
  PackageDescription,
  PackageHeaderSection,
  PackagePriceAndDetailsButtonsSection,
  PackageSection,
  PackageTitle,
  StyledWrapper,
  ToggleButton,
  Wrapper,
} from './styles';
import { isPromoPackage } from '../../utils';
import {} from '@cv/portal-common-lib';
import { PackagePrice } from '../Price/PackagePrice';
import Image from '../../../components/Image';
import PaidPromoPackageInfo from './PaidPromoPackageInfo';
import Products from '../../Products';
import { EligiblePackageInfo, PackageSubscription } from '../../Types';
import {
  CommonWebContent,
  ContentfulAssets,
  ContentMap,
  DiscountWebContent,
  SubscriptionProps,
} from '../../Subscription';
import CurrentPackage from '../CurrentPackage';
import { Divider } from '@mui/material';

interface TieredPackageProps {
  eligiblePackage: EligiblePackageInfo;
  subscriptionProps: SubscriptionProps;
  commonWebContent: CommonWebContent;
  discountWebContent: DiscountWebContent;
  productMatchingBundle: PackageSubscription;
  pkgIndex: number;
  location: string;
  isActive: boolean;
  assets: ContentfulAssets;
  perTermsLabel: ContentMap;
  currentPackageLabel: string;
  selected?: boolean;
  isForbidden?: boolean;
  onSelect: (pkg: EligiblePackageInfo) => void;
  onProductInfoToggle: (index: number) => void;
}

const EligiblePackage = ({
  eligiblePackage,
  subscriptionProps,
  commonWebContent,
  discountWebContent,
  productMatchingBundle,
  pkgIndex,
  location,
  isActive,
  assets,
  perTermsLabel,
  currentPackageLabel,
  selected,
  isForbidden,
  onSelect,
  onProductInfoToggle,
}: TieredPackageProps) => {
  const { shouldIncludeTax, productsToHide, currencyLabel } = commonWebContent;
  const { packageName, marketingName, shortDescription, tier, isPkgSubscribed, variant, products } = eligiblePackage;
  const { actualPrice, taxTotal, initialTerm } = variant;
  const {
    vehicleDetails: { make },
    locale,
    tenantId,
    customerCareCancelPackageNumber,
  } = subscriptionProps;
  const packageTierAnalyticsEvent = `Packages::PackageTier${tier}`;

  const togglePriceButton = (
    <ToggleButton
      disabled={isForbidden}
      variant={selected ? 'contained' : 'outlined'}
      onClick={() => onSelect(eligiblePackage)}
    >
      {productMatchingBundle ? (
        `Included in ${productMatchingBundle.marketingName}`
      ) : (
        <PackagePrice
          shouldIncludeTax={shouldIncludeTax}
          perTermsLabel={perTermsLabel}
          currencyLabel={currencyLabel}
          amountWithoutTax={actualPrice}
          taxTotal={taxTotal}
          term={initialTerm}
        />
      )}
    </ToggleButton>
  );

  return (
    <div style={{ width: '100%' }}>
      <Wrapper showBottomBorder={false}>
        <PackageSection>
          <PackageHeaderSection>
            <PackageTitle>{marketingName}</PackageTitle>
            <PackagePriceAndDetailsButtonsSection>
              {isPkgSubscribed ? (
                <StyledWrapper>
                  <CurrentPackage assets={assets} currentPackageLabel={currentPackageLabel} />
                </StyledWrapper>
              ) : (
                togglePriceButton
              )}
              <IconSection onClick={() => onProductInfoToggle(pkgIndex)}>
                <Image assets={assets} name={isActive ? 'ArrowUp' : 'ArrowDown'} />
              </IconSection>
            </PackagePriceAndDetailsButtonsSection>
          </PackageHeaderSection>
          {isPromoPackage(variant) && (
            <PaidPromoPackageInfo
              packageName={packageName}
              variant={variant}
              location={location}
              perTermsLabel={perTermsLabel}
              discountWebContent={discountWebContent}
              commonWebContent={commonWebContent}
              assets={assets}
              customerCareCancelPackageNumber={customerCareCancelPackageNumber}
            />
          )}
        </PackageSection>
      </Wrapper>
      {isActive && (
        <DropdownSection showBottomBorder={false}>
          <PackageDescription>{shortDescription}</PackageDescription>
          <Products
            assets={assets}
            products={products}
            productsToHide={productsToHide}
            make={make}
            locale={locale}
            tenantId={tenantId}
            location={location}
          />
        </DropdownSection>
      )}
      <Divider />
    </div>
  );
};

export default EligiblePackage;
