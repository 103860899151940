import { InitialStateType } from '@manageSubscription/Types';
import { TenantIds } from '@manageSubscription/utils/productFlowMap';
import { QueryClient } from '@tanstack/react-query';
import { History } from 'history';
import fca from './flows/common';
import nmex from './flows/nmex';
import { SubscriptionProps, WebContent } from '@manageSubscription';
import { ComponentRoutes } from './types';
import toyota from './flows/toyota';
import { SpinnerTypes } from '../components/Spinner/Spinner';

type FcaKeys = keyof typeof fca;
type NmexKeys = keyof typeof nmex;
type ToyotaKeys = keyof typeof toyota;

export enum FlowEventName {
  SET_FLOW = 'SET_FLOW',
  UPDATE_CONTEXT = 'UPDATE_CONTEXT',
  PUSH_HISTORY = 'PUSH_HISTORY',
  SET_SUBSCRIPTION_PROPS = 'SET_SUBSCRIPTION_PROPS',
  SET_SESSION_STORAGE = 'SET_SESSION_STORAGE',
  NAVIGATE_TO_ERROR = 'NAVIGATE_TO_ERROR',
  NAVIGATE_TO_SKIP = 'NAVIGATE_TO_SKIP',
  NAVIGATE_TO_CANCEL = 'navigateToCancel',
  NAVIGATE_BACK = 'navigateBack',
  NAVIGATE_FORWARD = 'navigateForward',
  SET_LOADING = 'setLoading',
  UNSET_LOADING = 'unsetLoading',
  SET_CHOSEN_WAY_TO_PAY = 'setChosenWayToPay',
  CONFIRM = 'confirm',
}

export type ApplicationFlowEvent =
  | {
      type: 'SET_FLOW';
      data: { location?: string; tenantId: TenantIds };
    }
  | {
      type: 'UPDATE_CONTEXT';
      data: {
        subscriptionProps: SubscriptionProps;
        content: WebContent;
        history: History;
      };
    }
  | {
      type: 'fca' | 'nmex' | 'toyota' | FcaKeys | NmexKeys | ToyotaKeys;
    }
  | {
      type: 'PUSH_HISTORY';
      data: { componentRoute: ComponentRoutes };
    }
  | {
      type: 'SET_SUBSCRIPTION_PROPS';
      data: Partial<SubscriptionProps>;
    }
  //to be changed
  | any;

export type ApplicationFlowContext = {
  clearSubscriptionPropsCache: () => void;
  subscriptionProps: SubscriptionProps;
  content: WebContent;
  flow?: string;
  queryClient?: QueryClient;
  loading: {
    isLoading: boolean;
    spinnerType: SpinnerTypes;
  };
  history?: History;
  skipToRoute?: ComponentRoutes;
  flowSessionStorage: InitialStateType;
  fetchPackagesMachineRef: any;
  showDesignatedDealerMessage: boolean;
};
