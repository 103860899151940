import React, { useEffect, useState } from 'react';
import { EligiblePackageInfo, PreviewOrderRequestInfo, SubscribedPackageInfo } from '../Types';
import ManagePromoPackageList, { ManagePromoPackageListProps } from './ManagePromoPackageList';
import { SubscriptionProps } from '../Subscription';
import Spinner from '../../components/Spinner';
import { applyPromoCodeOnPackages } from '../../services';

type ManagePromoCodePackagesProps = {
  promoCodePackages: EligiblePackageInfo[];
  subscribedPackages: SubscribedPackageInfo[];
  subscriptionProps: SubscriptionProps;
  previewOrderRequestInfo: PreviewOrderRequestInfo;
  addDiscountInfo: () => void;
} & Omit<ManagePromoPackageListProps, 'promoPackages'>;

const ManagePromoCodePackages: React.FC<ManagePromoCodePackagesProps> = ({
  subscriptionProps,
  promoCodePackages,
  subscribedPackages,
  previewOrderRequestInfo,
  addDiscountInfo,
  ...managePromoPackageListProps
}) => {
  const [isLoading, setLoading] = useState<boolean>();
  const [previewPromoCodePackages, setPreviewPromoCodePackages] = useState<EligiblePackageInfo[]>();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const promoCodeAppliedPackages = await applyPromoCodeOnPackages(subscriptionProps, previewOrderRequestInfo);
      setPreviewPromoCodePackages(promoCodeAppliedPackages);
      addDiscountInfo();
      setLoading(false);
    })();
    return (): void => {
      setPreviewPromoCodePackages([]);
    };
  }, [promoCodePackages]);

  if (isLoading) {
    return <Spinner />;
  }

  return <ManagePromoPackageList promoPackages={previewPromoCodePackages} {...managePromoPackageListProps} />;
};

export default ManagePromoCodePackages;
