import React, { useState, useEffect } from 'react';
import Select, { Props, components } from 'react-select';
import clsx from 'clsx';
import { FaCaretDown } from 'react-icons/fa';

import styles from './InputSelect.module.css';
import { darken, useTheme } from '@mui/material';

type SelectedOptionType = {
  value?: string;
  styleType: 'none' | 'primary';
};

type customProps = {
  nativeDropdown: boolean;
};

type InputSelectProps = Omit<Props, 'onChange'> & SelectedOptionType & customProps;

const DropdownIndicator = (props: any) => {
  const { DropdownIndicator } = components;
  return (
    DropdownIndicator && (
      <DropdownIndicator {...props}>
        <FaCaretDown />
      </DropdownIndicator>
    )
  );
};

const InputSelect = ({
  name = '',
  value,
  onChange,
  options,
  styleType = 'none',
  className = '',
  helpers = null,
  nativeDropdown = false,
  disabled = false,
  ...restProps
}: InputSelectProps) => {
  const getInitialValue = options?.find((option: any) => option.value === value || option.label === value);
  const [selectedOption, setSelectedOption] = useState(getInitialValue);
  const theme = useTheme();

  useEffect(() => {
    if (options && value) {
      handleChange(options.find((opt) => opt.value === value));
    }
  }, [value]);

  const handleChange = (selectedOption: SelectedOptionType) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption);

    // Helpers is an object from formik useField
    // it is needed to change state in Formik for custom inputs
    if (helpers) {
      const { setValue } = helpers;
      setValue(selectedOption?.value);
    }
  };

  // immediately update value if it was selected based on label
  // this fixes some translation weirdness
  useEffect(() => {
    if (getInitialValue && value !== getInitialValue.value) {
      handleChange(getInitialValue);
    }
  }, [value, getInitialValue]);

  return (
    <Select
      options={options}
      value={selectedOption}
      components={{ ...(!nativeDropdown && { DropdownIndicator }) }}
      onChange={onChange && handleChange}
      classNamePrefix={styles['input-select']}
      className={clsx(styles['input-select-container'], className, `${styles[`input-select-${styleType}`]}`)}
      isDisabled={disabled}
      {...restProps}
      theme={(t) => ({
        ...t,
        colors: {
          ...t.colors,
          text: theme.palette.text.primary,
          background: 'yellow',
          primary: theme.palette.primary.main,
          primary25: darken(theme.palette.primary.main, 0.25),
          primary50: darken(theme.palette.primary.main, 0.5),
        },
      })}
    />
  );
};

export default InputSelect;
