import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ApiProvider } from '@api';
import App from '@components/App';

import configureStore from './store';

import './index.css';
import './commons.css';

// Custom Media Queries
import './customMedia.css';

import './themes/common.theme.css';

// Dealer portal theme
import './themes/common.dealer.theme.css';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import queryClient from '@api/queries/queryClient';
import { ConfigProvider } from '@components/ConfigProvider';
import { ContentfulInitWrapper } from '@components/ContentfulInitWrapper';
import { DialogProvider } from '@components/Dialog/useDialog';

const { store, persistor } = configureStore();

// we need it in order to remove all react persist values in localStorage that were added before
window.localStorage?.clear();

ReactDom.render(
  <QueryClientProvider client={queryClient}>
    <ConfigProvider>
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ContentfulInitWrapper>
              <DialogProvider>
                <ApiProvider>
                  <App />
                  <ReactQueryDevtools initialIsOpen={false} />
                </ApiProvider>
              </DialogProvider>
            </ContentfulInitWrapper>
          </PersistGate>
        </Provider>
      </React.StrictMode>
    </ConfigProvider>
  </QueryClientProvider>,

  document.getElementById('root'),
);
