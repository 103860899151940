import { useRef, useEffect } from 'react';
import { useTheme } from '@mui/material';
import {
  EventType,
  EventDataBuilder,
  PortalPageLoadArgs,
  sendAnalyticsEvent,
} from '@app/components-lib/components/Analytics';
import useMediaQuery from '@hooks/useMediaQuery';
import useVehicle from '@api/queries/useVehicle';
import useAccount from '@api/queries/useAccount';
import { useConfig } from '@components/ConfigProvider';

const useFirePageLoadEvents = (loadEventArgs: Partial<PortalPageLoadArgs> = {}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.up('sm'));
  const config = useConfig();
  const { data: vehicle } = useVehicle();
  const { data: account } = useAccount();
  const oem = config.getOemName();
  const areEventsFired = useRef(false);

  useEffect(() => {
    if (vehicle?.vehicleId && vehicle?.vin && !areEventsFired.current) {
      const events = [
        new EventDataBuilder(EventType.PortalPageLoadStartedEvent).withArgs({
          isMobile: isMobileView,
          oem,
          pageName: window.location.pathname,
          vehicleID: vehicle?.vehicleId,
          vin: vehicle?.vin,
          siteCountry: account?.billingCountry || 'unknown country',
          siteLanguage: account?.preferredLanguage || 'en-US',
          siteName: window.location.hostname,
          ...loadEventArgs,
        }),
        new EventDataBuilder(EventType.PageLoadEvent).withArgs(),
      ];
      events.forEach(sendAnalyticsEvent);
      areEventsFired.current = true;
    }
  }, [
    isMobileView,
    oem,
    vehicle?.vehicleId,
    vehicle?.vin,
    account?.billingCountry,
    account?.preferredLanguage,
    loadEventArgs,
  ]);
};

export default useFirePageLoadEvents;
