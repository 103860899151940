export enum EventType {
  UserEvent = 'User Detected',
  UserSignedIn = 'User Signed In',
  UserSignedOut = 'User Signed Out',
  UserSignInErrored = 'User Sign In Errored',
  OrderEvent = 'Order Placed',
  CheckoutStepEvent = 'Checkout Step Encountered',
  PageLoadEvent = 'Page Load Completed',
  PageLoadStartedEvent = 'Page Load Started',
  PortalPageLoadStartedEvent = 'Portal Page Load Started',
  FormStartedEvent = 'Form Started',
  FormFailedEvent = 'Form Submission Failed',
  FormSucceededEvent = 'Form Submission Succeeded',
  FormViewedEvent = 'Form Viewed',
  UserProfileUpdatedEvent = 'User Profile Updated',
  ProductListingDisplayedEvent = 'Product Listing Displayed',
  ProductAddedToCartEvent = 'Product Added to Cart',
  CancelationClick = 'Cancellation Custom Click',
  CancelSubscription = 'Cancel Subscription',
  SubmitPackagesOfCancelFlow = 'Submit Cancelled Subscription',
  EnrollCancelClick = 'Enrollment_CancellationFlow',
}

export type UserSignInErroredData = {
  event: EventType.UserSignInErrored;
};

type ProductData = {
  price: {
    priceTier: string;
  };
  productInfo: {
    name: string;
    package_id: string;
    productDiscount: string;
    productID: string;
    variant_id: string;
  };
};

export type ProductAddedToCartData = {
  event: EventType.ProductAddedToCartEvent;
  product: ProductData[];
};

export type ProductListingDisplayedData = {
  event: EventType.ProductListingDisplayedEvent;
  listingDisplayed: {
    listing: ProductData[];
  };
};

export type UserProfileUpdatedData = {
  event: EventType.UserProfileUpdatedEvent;
  user: {
    custKey: string;
    hashedEmail: string;
  };
};

export type FormViewedData = {
  event: EventType.FormViewedEvent;
  form: {
    formName: string;
  };
};

export type FormSucceededData = {
  event: EventType.FormSucceededEvent;
  form: {
    formName: string;
  };
};

export type FormFailedData = {
  event: EventType.FormFailedEvent;
  form: {
    formError: string;
    formName: string;
  };
};

export type FormStartedData = {
  event: EventType.FormStartedEvent;
  form: {
    formName: string;
  };
};

export type UserSignedOutData = {
  event: EventType.UserSignedOut;
  user: {
    custKey: string;
  };
};

export type UserSignedInData = {
  event: EventType.UserSignedIn;
  user: {
    custKey: string;
    hashedEmail: string;
  };
};

export type UserDataLoginStatus = 'logged out' | 'logged in';
export type UserData = {
  event: EventType.UserEvent;
  user: {
    custKey: string;
    loginStatus: UserDataLoginStatus;
  };
};

export type OrderData = {
  event: EventType.OrderEvent;
  transaction: {
    item: {
      price: {
        sellingPrice: number;
      };
      productInfo: {
        name: string;
        package_id: string;
        priceTier: string;
        productID: string;
        productDiscount: string;
        variant_id: string;
      };
      quantity: number;
      tax?: number;
      voucherDiscount: {
        orderLevelDiscountAmount?: number;
        orderLevelDiscountCode?: string;
      };
    }[];
    payment: [
      {
        paymentGateway: string;
        paymentID: string;
      },
    ];
    purchaseID: string;
    total: {
      currency: string;
    };
    transactionID: string;
  };
};

export type CheckoutStepData = {
  event: EventType.CheckoutStepEvent;
  eventDetails: {
    checkoutStep: string;
  };
  product: {
    price: {
      priceTier: string;
    };
    productInfo: {
      name: string;
      package_id: string;
      productID: string;
      variant_id: string;
    };
  }[];
};

export type PageLoadData = {
  event: EventType.PageLoadEvent;
};

export type PageLoadStartedData = {
  event: EventType.PageLoadStartedEvent;
  page: {
    customer_flow: string;
    oem: string;
    pageCategory: string;
    pageName: string;
    pageType: string;
    siteCountry: string;
    siteExperience: string;
    siteLanguage: string;
    siteName: string;
    subsection: string;
    subsection2: string;
    subsection3: string;
    vin_mask: string;
    vehicleID: string;
  };
};

export enum CancelationPageName {
  selectPackage = 'selectPackage',
  subscriptionBenefits = 'subscriptionBenefits',
  packagePromotions = 'packagePromotions',
  allPromotions = 'allPromotions',
  nextBestOffer = 'nextBestOffer ',
  reviewCancellation = 'reviewCancellation',
  cancelConfirmation = 'cancelConfirmation',
  nextPricePackage = 'nextPricePackage',
  cancelPayment = 'cancelPayment',
  cancelReviewOrder = 'cancelReviewOrder ',
  cancelOrderComplete = 'cancelOrderComplete',
  cancelError = 'cancelError',
}

export type CancelationClickData = {
  event: EventType.CancelationClick;
  click: {
    Text: string;
  };
  page: {
    pageName: CancelationPageName | 'profile' | 'unknown page due to some error';
  };
};

export type CancelSubscriptionData = {
  event: EventType.CancelSubscription;
  cancelReason: string;
};

export type SubmitPackagesOfCancelFlowData = {
  event: EventType.SubmitPackagesOfCancelFlow;
  itemSelected: string;
  product: {
    productInfo: {
      name: string;
      package_id: string;
      subs_label: string;
    };
  }[];
};

export type EnrollCancelClickData = {
  event: EventType.EnrollCancelClick;
  click: {
    Text: string;
  };
  page: {
    pageName: CancelationPageName | 'unknown page due to some error';
    vin_mask: string;
  };
  product: { productInfo: { package_id: string; name: string } }[];
};

export type EventDataType =
  | UserData
  | UserSignInErroredData
  | UserSignedInData
  | UserSignedOutData
  | OrderData
  | CheckoutStepData
  | PageLoadData
  | PageLoadStartedData
  | FormStartedData
  | FormFailedData
  | FormSucceededData
  | FormViewedData
  | UserProfileUpdatedData
  | ProductListingDisplayedData
  | ProductAddedToCartData
  | CancelationClickData
  | CancelSubscriptionData
  | SubmitPackagesOfCancelFlowData
  | EnrollCancelClickData;
