import React from 'react';
import Navigation, { NavigationProps as Props } from '@components/Navigation/Navigation';
import useFilterNavigation from '@components/Navigation/useFilterNavigation';

const FilteredNavigation = ({ content: links, linksFilter, ...others }: Props): JSX.Element => {
  const filteredLinks = useFilterNavigation(links, linksFilter);

  return <Navigation {...others} content={filteredLinks} />;
};

export default FilteredNavigation;
