import { isPaidPackage } from '@components-lib';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { isAfter, parse } from 'date-fns';

function clampSamePackages(pkgs: Array<SubscribedPackage>) {
  const obj: Record<string, SubscribedPackage> = {};

  pkgs.forEach((pkg) => {
    const key = `${pkg.packageId}-${pkg.tier}-${isPaidPackage(pkg)}`;
    const storedPackage = obj[key];
    if (!storedPackage) {
      obj[key] = pkg;
    }
    if (storedPackage && isAfter(getExpiredDate(pkg), getExpiredDate(storedPackage))) {
      // replace existing object
      obj[key] = pkg;
    }
  });

  return Object.values(obj) as Array<SubscribedPackage>;
}

export default clampSamePackages;
const getExpiredDate = ({ autoRenew, termEndDate, termServiceEndDate }: SubscribedPackage) => {
  return parse(autoRenew ? termEndDate : termServiceEndDate, 'yyyy-MM-dd', new Date());
};
