// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader {
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  position: static;
}

.Loader-inner {
  --width: calc(var(--theme-loader-size) * 1em);
  --border-width: calc(var(--width) / 6);

  animation: Loader 1.25s infinite linear;
  border: var(--border-width) solid lightgray;
  border-left: var(--border-width) solid var(--theme-loader-color);
  font-size: 10px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
}

.Loader-inner,
.Loader-inner::after {
  border-radius: 50%;
  width: var(--width);
  height: var(--width);
}

.Loader-inner--inverse {
  border-color: rgba(255, 255, 255, 0.1);
  border-left-color: #fff;
}

@keyframes Loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,oCAAoC;EACpC,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,6CAA6C;EAC7C,sCAAsC;;EAEtC,uCAAuC;EACvC,2CAA2C;EAC3C,gEAAgE;EAChE,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,oBAAoB;EACpB,wBAAwB;AAC1B;;AAEA;;EAEE,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,sCAAsC;EACtC,uBAAuB;AACzB;;AAEA;EACE;IAEE,uBAAuB;EACzB;;EAEA;IAEE,yBAAyB;EAC3B;AACF","sourcesContent":[".Loader {\n  align-items: center;\n  background: rgba(255, 255, 255, 0.5);\n  display: flex;\n  position: static;\n}\n\n.Loader-inner {\n  --width: calc(var(--theme-loader-size) * 1em);\n  --border-width: calc(var(--width) / 6);\n\n  animation: Loader 1.25s infinite linear;\n  border: var(--border-width) solid lightgray;\n  border-left: var(--border-width) solid var(--theme-loader-color);\n  font-size: 10px;\n  margin: 0 auto;\n  overflow: hidden;\n  position: relative;\n  text-indent: -9999em;\n  transform: translateZ(0);\n}\n\n.Loader-inner,\n.Loader-inner::after {\n  border-radius: 50%;\n  width: var(--width);\n  height: var(--width);\n}\n\n.Loader-inner--inverse {\n  border-color: rgba(255, 255, 255, 0.1);\n  border-left-color: #fff;\n}\n\n@keyframes Loader {\n  0% {\n    -webkit-transform: rotate(0deg);\n    transform: rotate(0deg);\n  }\n\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
