import React from 'react';
import { EligiblePackageInfo } from '../../Types/PackageInfo';
import { ContentfulAssets } from '../..';
import PackageDetailsContent from '@manageSubscription/Discount/PackageDetails/PackageDetailsContent';
import { PackageDetailsLink } from '@manageSubscription/Discount/PackageDetails/PackageDetailsStyles';

export interface PackageDetailProps {
  promoPackage?: EligiblePackageInfo;
  analyticsEventName?: string;
  closeButtonLabel: string;
  packageDetailsLabel: string;
  detailsLabel?: string;
  assets: ContentfulAssets;
}

const PackageDetails = ({
  promoPackage,
  analyticsEventName,
  closeButtonLabel,
  detailsLabel,
  packageDetailsLabel,
  assets,
}: PackageDetailProps) => {
  return (
    <PackageDetailsLink
      assets={assets}
      message={<PackageDetailsContent promoPackage={promoPackage} detailsLabel={detailsLabel} />}
      buttonText={closeButtonLabel}
      className="package-details-link link-open"
      analyticsEventName={analyticsEventName}
    >
      {packageDetailsLabel}
    </PackageDetailsLink>
  );
};

export default PackageDetails;
