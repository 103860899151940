import { TenantIds } from '@manageSubscription/utils/productFlowMap';
import { ComponentRoutes } from './types';
import { CompoundState } from './flows/componentFlow/Types';
import nissan2e from './flows/nna/2e';
import nissan2d from './flows/nna/2d';
import subaru2a from './flows/subaru/2a';
import common from './flows/common';
import honda_acura from './flows/honda-acura';
import nmex from './flows/nmex';
import toyota from './flows/toyota';
import { CommonComponentFlowMap } from './flows/componentFlow/common';
import { getComponentFlowTuple } from './flows/componentFlow';

const urlToPage: { [key: string]: string } = {
  trial: '1a',
  enroll: '1b',
  activate: '1c',
  subscribe: '2a',
  savemore: '2c',
  promocode: '2d',
  downgrade: '2e',
  learnmore: '2f',
  completeprofile: '2f',
} as const;

export type FlowLocation = keyof typeof urlToPage;
export const componentNames = Object.keys(CommonComponentFlowMap) as ComponentRoutes[];

export class Middleware {
  private readonly _tenantId: TenantIds;
  private readonly _flow: string;

  constructor(tenantId: TenantIds, flow?: string) {
    this._tenantId = tenantId;
    this._flow = flow || '';
  }

  getComponentFlow(route: ComponentRoutes) {
    const page = getComponentFlowTuple(route, this.tenantId);
    return page?.flow(this);
  }

  get tenantId() {
    return this._tenantId;
  }

  get flow() {
    return this._flow;
  }
}

export const getFlowCompoundState = (tenantMiddleware: Middleware): CompoundState => {
  const getState = (middleware: Middleware) => {
    const flowId: (typeof urlToPage)[keyof typeof urlToPage] = urlToPage[middleware.flow] || '2a';
    switch (middleware.tenantId) {
      case TenantIds.Nissan_MX:
      case TenantIds.Infiniti_MX:
        if (flowId === '2c') {
          return common[flowId];
        }
        if (flowId === '2d') {
          return nissan2d;
        }
        return nmex.default;
      case TenantIds.Toyota:
      case TenantIds.Lexus:
        return toyota.default;
      case TenantIds.Infiniti_CA:
      case TenantIds.Infiniti_US:
      case TenantIds.Nissan_CA:
      case TenantIds.Nissan_US:
        if (flowId === '2f') {
          return nissan2e;
        }
        return common[flowId];
      case TenantIds.Honda:
      case TenantIds.Acura:
        return honda_acura[flowId];
      case TenantIds.Subaru:
        if (flowId === '2a') {
          return subaru2a;
        }
        return common[flowId];
      default:
        return common[flowId];
    }
  };
  return getState(tenantMiddleware)(tenantMiddleware);
};

export const getPageComponent = (componentName: ComponentRoutes, tenantId: TenantIds) =>
  getComponentFlowTuple(componentName, tenantId)?.component;
