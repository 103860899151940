import { useApi } from '@api';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { login, loginWithCallback } from './login';
import { EventDataBuilder, EventType, sendAnalyticsEvent } from '@app/components-lib/components/Analytics';

export type AuthApi = {
  login: () => void;
  loginWithCallback: () => Promise<void>;
  error?: boolean;
  isLoading: boolean;
};

export type AuthProviderProps = {
  children?: React.ReactNode;
};

const AuthContext = React.createContext<AuthApi | null>(null);

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const history = useHistory();
  const api = useApi();
  const [error, setError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const wrapper = React.useCallback(
    (func: () => Promise<void>) => async (): Promise<void> => {
      setIsLoading(true);
      setError(false);

      try {
        await func();
      } catch (e) {
        sendAnalyticsEvent(new EventDataBuilder(EventType.UserSignInErrored).withArgs());
        setError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const initializedStrategy = React.useMemo(() => {
    return {
      login,
      error,
      isLoading,
      loginWithCallback: wrapper(loginWithCallback(history, api)),
    };
  }, [error, isLoading, history, api, wrapper]);

  return <AuthContext.Provider value={initializedStrategy}>{children}</AuthContext.Provider>;
};

const useAuth = (): AuthApi => {
  const api = React.useContext(AuthContext);

  if (api === null) {
    throw new Error('useAuth hook must be used within AuthProvider');
  }

  return api;
};

export { AuthProvider, useAuth };
