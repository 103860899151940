import UserProfileContainer from '@manageSubscription/UserProfile/MultiBrand/UserProfileContainer';
import PaymentWrapperContainer from '@manageSubscription/Payment/Honda';
import { ComponentRoutes } from '../../../types';
import { checkIfProfileIsComplete } from './CheckIfProfileIsComplete';
import { userProfileFlowHonda } from './UserProfile';
import { paymentFlow as commonPaymentFlow } from '@lib-appRouter/flows/componentFlow/common/Payment';

export const HondaComponentFlowMap = {
  [ComponentRoutes.userProfile]: { component: UserProfileContainer, flow: userProfileFlowHonda },
  [ComponentRoutes.checkIfProfileIsComplete]: { flow: checkIfProfileIsComplete },
  [ComponentRoutes.payment]: { component: PaymentWrapperContainer, flow: commonPaymentFlow },
};
