import { ServiceNotificationPreference } from '@cv/portal-cps-lib/vehicle/vehicle-notification-service/models/vehicle-notification-service';
import { VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';

export const filterNotificationServices = (
  notifyServices: ServiceNotificationPreference[],
  capableServices: VehicleServiceDetails[],
  labels: { [key: string]: string },
) => {
  return notifyServices?.filter((ns) => {
    const serviceName = labels[ns.telematicsServiceName];
    return (
      serviceName &&
      capableServices.some((cs) => cs.vehicleServiceName === serviceName || cs.marketingName === serviceName)
    );
  });
};
