import { OfferType, SalesChannel } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { OwnershipType } from '@cv/portal-cps-lib/vehicle/vehicle-management/enums';
import { flatMap, isEmpty } from 'lodash';
import { configs } from '../configs';
import {
  AUTH_NOTIFICATION_STATUSES,
  canSubscribeToPackages,
  getAuthNotificationStatusFromContext,
  getEligiblePaidPackages,
  hasActiveSubscription,
  hasEligiblePackageVariants,
  hasPackageAdded,
  noPromosPackagesAvailable,
} from '@manageSubscription';
import { TenantIds } from '@manageSubscription/utils/productFlowMap';
import { FlowLocation } from './flowMiddleware';
import { OperationType } from './flows/componentFlow/toyota/operationTypes';
import { AggregatedFlowContext } from './flows/componentFlow/Types';
import { ComponentRoutes } from './types';
import trialPackageConditions from './stateMachineConditions/trialPackageConditions';

export type Condition = (context: AggregatedFlowContext, event?: any) => boolean;

const packagesCountToSkipPaid = 2;
const subaruSkipPaid = (FlowContext) => {
  const { subscriptionProps, eligiblePromoPackages, flowSessionStorage } = FlowContext;
  const { subscribedPackages } = flowSessionStorage;
  const allDiscounts = flatMap(eligiblePromoPackages, (p) => p.variant.discounts);
  const promoCount = allDiscounts.filter((d) => d.offerType === OfferType.Promotional).length;
  const trialCount = allDiscounts.filter((d) => d.offerType === OfferType.Trial).length;

  if (subscriptionProps.salesChannel?.toUpperCase() === SalesChannel.Corp && promoCount >= 1) {
    return true;
  }
  if (subscribedPackages.length) {
    return false;
  }

  return (promoCount === 1 && trialCount === 1) || promoCount >= packagesCountToSkipPaid;
};

const subaruConditions: { [key: string]: Condition } = {
  skipPromoPackagesSubaru: (FlowContext) => {
    return !subaruSkipPaid(FlowContext);
  },
  skipPaidPackagesSubaru: (FlowContext) => {
    return subaruSkipPaid(FlowContext);
  },
};

const toyotaConditions: { [key: string]: Condition } = {
  isToyotaFlow: (FlowContext) =>
    FlowContext.subscriptionProps.tenantId === TenantIds.Toyota ||
    FlowContext.subscriptionProps.tenantId === TenantIds.Lexus,
  isToyotaPurchaseFlow: (FlowContext) => FlowContext.operationType === OperationType.purchase,
  skipFetchingPackages: () => configs.skipInitFetchPackages,
  isRegionAlreadyAdded: ({ subscriptionProps }) => !!subscriptionProps.userDetails.billingAddress.region,
};

export const conditions: { [key: string]: Condition } = {
  ...subaruConditions,
  ...toyotaConditions,
  ...trialPackageConditions,
  hasSkipToRouteProperty: (FlowContext) => {
    // if persisted page is ComponentRoutes.checkIfEmailIsOnFile, then we dont want to skip the flow
    ///(it's the first page for every flow and every OEM and it would naturally show up as the first page on its own)
    return !!FlowContext.skipToRoute && FlowContext.skipToRoute !== ComponentRoutes.checkIfEmailIsOnFile;
  },
  hasToken: (FlowContext) => {
    return !!FlowContext.subscriptionProps.accessToken;
  },
  hasEmailOnFile: (FlowContext) => {
    return !!FlowContext.subscriptionProps.userDetails.email;
  },
  isProfileComplete: (FlowContext) => {
    const userDetails = FlowContext.subscriptionProps?.userDetails;
    const billingAddress = userDetails?.billingAddress;
    return !!(
      !isEmpty(userDetails?.primaryPhone) &&
      userDetails?.isPinConfigured &&
      billingAddress &&
      billingAddress.address1 &&
      billingAddress.city &&
      billingAddress.state &&
      billingAddress.postalCode
    );
  },
  isNotEnrollmentFlow: (FlowContext) => {
    const enrollmentFlows: FlowLocation[] = ['trial', 'enroll', 'activate'];
    return !enrollmentFlows.includes(FlowContext.subscriptionProps.location as FlowLocation);
  },
  skipUserProfileComponent: (FlowContext) => {
    return [TenantIds.Infiniti_MX, TenantIds.Infiniti_US, TenantIds.Nissan_MX, TenantIds.Nissan_US].includes(
      FlowContext.subscriptionProps.tenantId as TenantIds,
    );
  },
  hasActiveSubscription: (FlowContext) => {
    const { subscribedPackages } = FlowContext.flowSessionStorage;
    return hasActiveSubscription(subscribedPackages);
  },
  hasNoEligiblePackageVariants: (FlowContext) => {
    const { eligiblePackages } = FlowContext.flowSessionStorage;
    return !hasEligiblePackageVariants(eligiblePackages);
  },
  hasNoEligiblePaidPackages: (FlowContext) => {
    const { eligiblePackages } = FlowContext.flowSessionStorage;
    const eligiblePaidPackages = getEligiblePaidPackages(eligiblePackages);
    return !eligiblePaidPackages?.length;
  },
  hasNoEligibleAddons: ({ eligibleAddOns }) => {
    return !eligibleAddOns?.length;
  },
  hasNoPackageSubscriptions: (FlowContext) => {
    const { packageSubscriptions } = FlowContext.flowSessionStorage;
    return !packageSubscriptions?.length;
  },
  canUserSubscribeToPackages: (FlowContext) => {
    const { tenantId } = FlowContext.subscriptionProps;
    const { eligiblePackages, subscribedPackages } = FlowContext.flowSessionStorage;
    return canSubscribeToPackages(subscribedPackages, eligiblePackages, tenantId);
  },
  isDataEmpty: (_, event) => {
    return isEmpty(event.data);
  },
  skipManagePaidPackages: (FlowContext) => {
    const { subscribedPackages, eligiblePackages } = FlowContext.flowSessionStorage;
    const { tenantId } = FlowContext.subscriptionProps;
    const canUserSubscribeToPackages = canSubscribeToPackages(subscribedPackages, eligiblePackages, tenantId);
    return canUserSubscribeToPackages && !getEligiblePaidPackages(eligiblePackages).length;
  },
  skipPromotion: (FlowContext) => {
    const { salesChannel } = FlowContext.subscriptionProps;
    const { subscribedPackages, eligiblePackages } = FlowContext.flowSessionStorage;
    const { eligiblePromoPackages } = FlowContext;
    return noPromosPackagesAvailable(eligiblePromoPackages, subscribedPackages, eligiblePackages, salesChannel);
  },
  hasEligiblePromoPackages: (FlowContext) => {
    return FlowContext?.eligiblePromoPackages.length > 0;
  },
  hasNoPromoCodePackages: (FlowContext) => {
    return FlowContext?.promoCodePackages.length === 0;
  },
  hasPromoPackage: (FlowContext) => {
    const { packageSubscriptions } = FlowContext.flowSessionStorage;
    const { eligiblePromoPackages } = FlowContext;
    return eligiblePromoPackages?.some((pkg) => hasPackageAdded(packageSubscriptions, pkg));
  },
  hasPromoCodePackage: (FlowContext) => {
    const { packageSubscriptions } = FlowContext.flowSessionStorage;
    const { promoCodePackages } = FlowContext;
    return promoCodePackages?.some((pkg) => hasPackageAdded(packageSubscriptions, pkg));
  },
  isCpo: (FlowContext) => {
    const {
      vehicleDetails: { ownershipType },
    } = FlowContext.subscriptionProps;
    return [OwnershipType.Cpo].includes(ownershipType);
  },
  notCpoPreOwned: (FlowContext) => {
    const {
      vehicleDetails: { ownershipType },
    } = FlowContext.subscriptionProps;
    return ![OwnershipType.Cpo, OwnershipType.PreOwned].includes(ownershipType);
  },

  isCurrentLocationReviewOrder: (FlowContext) => {
    return FlowContext.history?.location.pathname.substring(1) === ComponentRoutes.reviewOrder;
  },
  isUsVechicle: (FlowContext) => {
    const {
      vehicleDetails: { registrationCountry },
    } = FlowContext.subscriptionProps;
    return registrationCountry === 'USA';
  },
  isAuthCodeFunctionality: (FlowContext) => {
    return FlowContext.subscriptionProps?.isAuthCodeFunctionality;
  },
  isAuthFormHidden: (FlowContext, event) => {
    return getAuthNotificationStatusFromContext(FlowContext, event) === AUTH_NOTIFICATION_STATUSES.HIDE;
  },
  isAuthFormError: (FlowContext, event) => {
    return getAuthNotificationStatusFromContext(FlowContext, event) === AUTH_NOTIFICATION_STATUSES.ERROR;
  },
  skipPaymentScreen: (FlowContext) => {
    return !FlowContext.flowSessionStorage.packageSubscriptions.some((packageSubscription) => {
      const discounts = packageSubscription?.variant?.discounts;
      // we should cover case when we don't have discounts, discounts = null
      // and when we have discount as empty array
      if (!discounts?.length) {
        return true;
      }

      return packageSubscription?.variant?.discounts?.some((discount) => {
        const ccRequired = typeof discount.ccRequired === 'boolean' ? discount.ccRequired : true;

        if (ccRequired) {
          return true;
        }

        const actualPrice = packageSubscription?.variant?.actualPrice;
        let price = actualPrice ?? 0;
        price = typeof actualPrice === 'number' ? actualPrice : parseFloat(actualPrice);
        return price > 0;
      });
    });
  },
};
