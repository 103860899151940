// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.W9zEYaT1WBG8KXWmYSjP {
  height: auto;
  margin-top: 1.125rem;
}
.xIActAjd0k5pH2TkYorp {
  display: none;
}

.wBmVZQixW2xb26YHvlks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: var(--theme-vehicle-status-warning-banner-header-background);
  justify-items: center;
  height: 60px;
}

.LBHFjMzDsfY7VdCM6rav {
  display: flex;
  align-items: center;
  grid-column-start: 2;
}

.I6RTOFi32QCKWua7CEi9 {
  color: var(--theme-vehicle-status-warning-banner-header-color);
  font-size: 1rem;
  margin-left: 1rem;
}

.MMX16734yWlfO62tyszy {
  align-self: center;
  margin-left: auto;
  margin-right: 1rem;
  cursor: pointer;
  color: var(--theme-vehicle-status-warning-banner-header-color);
}

/* Content */

.NqhffGE_9DiyY0OHiDkp {
  display: flex;
  flex-direction: column;
  background-color: var(--theme-vehicle-status-warning-banner-content-background);
  padding: 1rem 0;
}

@media (min-width: 768px) {
  .NqhffGE_9DiyY0OHiDkp {
    padding: 2rem 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/WarningBanner/WarningBanner.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,oBAAoB;AACtB;AACA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,8EAA8E;EAC9E,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,8DAA8D;EAC9D,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,8DAA8D;AAChE;;AAEA,YAAY;;AAEZ;EACE,aAAa;EACb,sBAAsB;EACtB,+EAA+E;EAC/E,eAAe;AACjB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".warning-banner {\n  height: auto;\n  margin-top: 1.125rem;\n}\n.warning-banner--hidden {\n  display: none;\n}\n\n.warning-banner-header {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  background-color: var(--theme-vehicle-status-warning-banner-header-background);\n  justify-items: center;\n  height: 60px;\n}\n\n.warning-banner-header-elements {\n  display: flex;\n  align-items: center;\n  grid-column-start: 2;\n}\n\n.warning-banner-text {\n  color: var(--theme-vehicle-status-warning-banner-header-color);\n  font-size: 1rem;\n  margin-left: 1rem;\n}\n\n.warning-banner-chevron {\n  align-self: center;\n  margin-left: auto;\n  margin-right: 1rem;\n  cursor: pointer;\n  color: var(--theme-vehicle-status-warning-banner-header-color);\n}\n\n/* Content */\n\n.warning-banner-content {\n  display: flex;\n  flex-direction: column;\n  background-color: var(--theme-vehicle-status-warning-banner-content-background);\n  padding: 1rem 0;\n}\n\n@media (--viewport-s) {\n  .warning-banner-content {\n    padding: 2rem 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning-banner": `W9zEYaT1WBG8KXWmYSjP`,
	"warning-banner--hidden": `xIActAjd0k5pH2TkYorp`,
	"warning-banner-header": `wBmVZQixW2xb26YHvlks`,
	"warning-banner-header-elements": `LBHFjMzDsfY7VdCM6rav`,
	"warning-banner-text": `I6RTOFi32QCKWua7CEi9`,
	"warning-banner-chevron": `MMX16734yWlfO62tyszy`,
	"warning-banner-content": `NqhffGE_9DiyY0OHiDkp`
};
export default ___CSS_LOADER_EXPORT___;
