import React, { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { Switch as BaseSwitch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import useLabels from '@hooks/useLabels';
import useRenderPage from '@hooks/useRenderPage';
import { filter } from 'lodash';
import { Helmet } from 'react-helmet';
import flattenChildren from 'react-flatten-children';
import Login from '@components/Login/Login';
import Logout from '@components/Login/Logout';
import SecurityAuthentication from '@components/SecurityAuthentication';
import LoginCallBack from '../Login/LoginCallBack';
import AppWrapper from './AppWrapper';
import VinStatusPage from '@components/VinStatusPage';
import { ServiceOverviewPage } from '@components/ServiceOverview';
import { TermsAndConditionsPage } from '@components/TermsAndConditions';
import AccountEnrollment from '@components/AccountEnrollment/AccountEnrollment';
import VinSearch from '@components/VinSearch/VinSearch';
import { CancelSubscription } from '@components/CancelSubscription/CancelSubscription';
import Cancellation from '@components/Cancellation';
import { PageContent } from './AppWrapper';
import { SecureRoute } from './SecureRoute';
import { useNavigation } from '@components/Navigation';
import { Page, Path } from '@customTypes/Navigation';
import { AppRoute } from './AppRoute';
import useIsAuthorised from '@hooks/useIsAuthorised';
import { EventDataBuilder, EventType, sendAnalyticsEvent } from '@app/components-lib/components/Analytics';
import useVehicle from '@api/queries/useVehicle';
import useAccount from '@api/queries/useAccount';
import { useTheme } from '@mui/material';
import useMediaQuery from '@hooks/useMediaQuery';
import { useConfig } from '@components/ConfigProvider';
import urlSearchParams from '@utils/urlSearchParams';
import { SUBSCRIPTION_PAGE_URL, SUBSCRIPTION_PAGE } from './UrlParser';

const Switch = ({ children }: { children: ReactNode }) => <BaseSwitch>{flattenChildren(children)}</BaseSwitch>;
type AppRouterProps = {
  isLoginEnabled: boolean;
  pageContent?: PageContent;
};
const loginRouterEmbeddedPaths = {
  login: { path: '/login' },
};
function AppRouter({ isLoginEnabled, pageContent = {} }: AppRouterProps) {
  const { pagesList = [], unauthorizedPages = [], fallbackPages = [], unauthFallbackPages = [] } = pageContent;
  const location = useLocation();
  const RenderPage = useRenderPage(pageContent);

  const { paths } = useNavigation();
  const isAuthorised = useIsAuthorised();

  const { data: vehicle } = useVehicle();
  const { data: account } = useAccount();
  const history = useHistory();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.up('sm'));
  const config = useConfig();

  const filterPageContentByPath = (path: string) => filter(pagesList, ['path', path]).map((page) => page)[0] || [];
  const getPageContentSections = (path: string) => filterPageContentByPath(path)?.contentSections || {};
  const getPageAnalyticsEventName = (path: string) => filterPageContentByPath(path)?.analyticsEventName || '';

  const [authPageLabel] = fallbackPages;
  const [guestPageLabel] = unauthFallbackPages;
  const initialAuthPage = authPageLabel?.labelValue;
  const initialGuestPage = guestPageLabel?.labelValue;

  const currentPage = pagesList?.find((page) => {
    const availablePaths =
      page.path?.split(', ') || page.contentSections.map((innerPage: { path: string }) => innerPage.path);

    return availablePaths.includes(location.pathname);
  });

  const urls = useMemo(
    () =>
      paths
        .filter(({ authorised }) => (isAuthorised ? authorised : !authorised))
        .reduce<string[]>((acc: Array<string>, path: Path) => {
          const pathUrl = path.subPages?.map((subPage) => subPage.url) ?? path.url;
          return pathUrl ? acc.concat(pathUrl) : acc;
        }, []),
    [isAuthorised, paths],
  );

  const labels = useLabels(currentPage?.pageTitle);
  const titles = labels.getAllPrimaries();
  const [pageTitle] = Object.values(titles) || [''];

  const getInitialPage = () => {
    const getCleanPath = (path: string | undefined) => path?.replace(/^\//, '');
    const getFallbackPage = (pageList: Page[], fallback: string | undefined) => {
      const cleanFallBack = getCleanPath(fallback);
      const listHasFallback = pageList.find((p) => getCleanPath(p?.path) === cleanFallBack);

      if (fallback && listHasFallback) {
        return fallback;
      }
      return pageList[0]?.path;
    };
    return isAuthorised
      ? getFallbackPage(pagesList, initialAuthPage)
      : getFallbackPage(
          [...unauthorizedPages, ...(Object.values(loginRouterEmbeddedPaths) as Page[])],
          initialGuestPage,
        );
  };

  useEffect(() => {
    sendAnalyticsEvent(
      new EventDataBuilder(EventType.UserEvent).withArgs({
        userStatus: isAuthorised ? 'logged in' : 'logged out',
        userId: account?._id,
      }),
    );
  }, [isAuthorised, account?._id]);

  const trackPageLoad = useCallback(
    (location: Location) => {
      const { flowName } = urlSearchParams.getAll<{ flowName: string }>();

      const customerFlows: { [key in string]: string } = {
        [SUBSCRIPTION_PAGE_URL]: flowName || SUBSCRIPTION_PAGE,
      };

      const formattedPages: { [key in string]: string } = {
        [SUBSCRIPTION_PAGE_URL]: flowName ? `/${flowName}` : SUBSCRIPTION_PAGE_URL,
      };

      sendAnalyticsEvent(
        new EventDataBuilder(EventType.PortalPageLoadStartedEvent).withArgs({
          customer_flow: customerFlows[location.pathname],
          isMobile: isMobileView,
          oem: config.getOemName(),
          pageName: formattedPages[location.pathname] || location.pathname,
          vehicleID: vehicle?.vehicleId,
          vin: vehicle?.vin,
          siteCountry: account?.billingCountry,
          siteLanguage: account?.preferredLanguage,
          siteName: window.location.hostname,
        }),
      );

      sendAnalyticsEvent(new EventDataBuilder(EventType.PageLoadEvent).withArgs());
    },
    [account?.billingCountry, account?.preferredLanguage, config, isMobileView, vehicle?.vehicleId, vehicle?.vin],
  );

  useEffect(() => {
    const unlisten = history.listen(trackPageLoad);

    // Clean up the listener when the component is unmounted
    return unlisten;
  }, [history, location.pathname, trackPageLoad]);

  // * we need this workaround to track when user lands on the page with direct url
  const landEventSended = useRef(false);
  useEffect(() => {
    if (!landEventSended.current) {
      trackPageLoad(location);
      landEventSended.current = true;
    }
  }, [location, trackPageLoad]);

  const initialPage = getInitialPage();

  return (
    <>
      {pageTitle && <Helmet defaultTitle={pageTitle as string} />}
      <Switch>
        {isLoginEnabled && (
          <>
            <Route exact path={loginRouterEmbeddedPaths.login.path} component={Login} />
            <Route exact path="/loginCallBack" component={LoginCallBack} />
            <Route exact path="/resetPassword" render={(props) => <RenderPage {...props} name="resetPassword" />} />
            <Route
              exact
              path="/authenticate"
              render={(props) => (
                <SecurityAuthentication
                  {...Object.assign(props, {
                    contentSections: getPageContentSections('/authenticate'),
                    analyticsEventName: getPageAnalyticsEventName('/authenticate'),
                  })}
                />
              )}
            />
            <SecureRoute path="/logout" Component={Logout} />
            <SecureRoute
              path="/cancel"
              Component={CancelSubscription}
              componentProps={{ contentSections: getPageContentSections('/cancel') }}
            />
            <SecureRoute
              path="/cancelation"
              Component={Cancellation}
              componentProps={{ contentSections: getPageContentSections('/cancel') }}
            />
            <SecureRoute
              path="/vinStatus"
              Component={VinStatusPage}
              componentProps={{ contentSections: getPageContentSections('vinStatus') }}
            />
            <SecureRoute path="/vinSearch" Component={VinSearch} />
            <SecureRoute
              path="/serviceOverview"
              Component={ServiceOverviewPage}
              componentProps={{ content: getPageContentSections('serviceOverview') }}
            />
            <SecureRoute
              path="/termsConditions"
              Component={TermsAndConditionsPage}
              componentProps={{ content: getPageContentSections('termsConditions') }}
            />
            <SecureRoute path="/accountEnrollment" Component={AccountEnrollment} componentProps={{ pagesList }} />
          </>
        )}

        <AppRoute path={urls} Component={AppWrapper} componentProps={{ pageContent }} />
        <Redirect to={`${String(initialPage)}${(!isLoginEnabled && location.search) || ''}`} />
      </Switch>
    </>
  );
}
export default AppRouter;
