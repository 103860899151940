import React from 'react';
import { Container, ContentCenter } from '../../styled-components/globalStyles';
import { PaidPackages } from '../Packages';
import { LegalContainer, PackagesLegal } from '../Legal';
import { ContinueButton, ContinueButtonsWrapper } from './styles';
import { EligiblePackageInfo, PackageSubscription, SubscribedPackageInfo } from '../Types';
import {
  CommonWebContent,
  ContentfulAssets,
  DiscountWebContent,
  PackagesWebContent,
  TrialPackagesWebContent,
} from '../Subscription';
import { PaidPackagesProps } from '../Packages/PaidPackage/PaidPackages';
import PackageSection from '@manageSubscription/Packages/PaidPackage/PackageSection';

export type ManagePaidPackagesProps = {
  farthestSubscriptions: SubscribedPackageInfo[];
  noMorePackagesAvailable: string;
  canUserSubscribeToPackages: boolean;
  onlyTrialInPackages: boolean;
  hasPaidPackageSelected: boolean;
  eligiblePaidPackages: EligiblePackageInfo[];
  isTrialEligible: boolean;
  canShowBackButton: boolean;
  customerCareCancelPackageNumber: string;
  location: string;
  commonWebContent: CommonWebContent;
  packagesWebContent: PackagesWebContent;
  trialPackagesWebContent: TrialPackagesWebContent;
  discountWebContent: DiscountWebContent;
  assets: ContentfulAssets;
  getHighestSubscriptionHeaderText: () => string;
  handleAddServices: () => void;
  navigateForward: () => void;
  navigateBack?: () => void;
} & Omit<PaidPackagesProps, 'packages' | 'termsLabel' | 'currentPackageLabel' | 'perTermsLabel' | 'tenantId'>;

const ManagePaidPackages: React.FC<ManagePaidPackagesProps> = ({
  farthestSubscriptions,
  noMorePackagesAvailable,
  canUserSubscribeToPackages,
  onlyTrialInPackages,
  hasPaidPackageSelected,
  eligiblePaidPackages,
  isTrialEligible,
  canShowBackButton,
  customerCareCancelPackageNumber,
  location,
  commonWebContent,
  packagesWebContent,
  trialPackagesWebContent,
  discountWebContent,
  assets,
  getHighestSubscriptionHeaderText,
  handleAddServices,
  navigateForward,
  navigateBack,
  ...paidPackagesProps
}) => {
  const { skipAndContinueButtonLabel, backButtonLabel } = commonWebContent;
  const { addServicesButtonLabel, packagesDescription } = packagesWebContent;
  const { trialEligibleHeader, trialEligibleSubheader, packageSelectionTrialTermsDisclaimer } = trialPackagesWebContent;
  const { tenantId } = paidPackagesProps.subscriptionProps;

  const header = isTrialEligible ? trialEligibleHeader : getHighestSubscriptionHeaderText();
  const subHeader = isTrialEligible ? trialEligibleSubheader : packagesDescription;

  const continueButton =
    onlyTrialInPackages && !isTrialEligible ? (
      <ContinueButton name="continueBtn" onClick={navigateForward}>
        {skipAndContinueButtonLabel}
      </ContinueButton>
    ) : (
      <ContinueButton
        name="continueBtn"
        data-testid="paid-pkg-continue-button"
        onClick={handleAddServices}
        disabled={!hasPaidPackageSelected}
      >
        {addServicesButtonLabel}
      </ContinueButton>
    );

  return (
    <Container>
      {canUserSubscribeToPackages ? (
        <>
          <PackageSection header={header} subHeader={subHeader}>
            <PaidPackages
              {...paidPackagesProps}
              discountWebContent={discountWebContent}
              tenantId={tenantId}
              perTermsLabel={packagesWebContent.eligiblePackagesWebContent.perTermsLabel}
              currentPackageLabel={packagesWebContent.currentPackageLabel}
              commonWebContent={commonWebContent}
              assets={assets}
              location={location}
              termsLabel={packagesWebContent.eligiblePackagesWebContent.termsLabel}
              packages={eligiblePaidPackages}
            />
          </PackageSection>
          <ContinueButtonsWrapper>
            {continueButton}
            {canShowBackButton && (
              <ContinueButton variant="outlined" onClick={navigateBack}>
                {backButtonLabel}
              </ContinueButton>
            )}
          </ContinueButtonsWrapper>
        </>
      ) : (
        <ContentCenter>{noMorePackagesAvailable}</ContentCenter>
      )}

      {isTrialEligible && packageSelectionTrialTermsDisclaimer ? (
        <LegalContainer legalText={packageSelectionTrialTermsDisclaimer} showCheckbox={false} />
      ) : (
        <PackagesLegal />
      )}
    </Container>
  );
};

export default ManagePaidPackages;
