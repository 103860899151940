import React, { useState } from 'react';
import { useCancelMachineContext } from '../CancelSubscriptionStateMachineWrapper';
import { useIsMutating } from '@tanstack/react-query';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useCancelLabels } from '../CancelLabelsContext';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import NavigationProp from '@customTypes/NavigationProp';
import useAccount from '@api/queries/useAccount';
import usePaymentMethod from '@api/queries/usePaymentMethod';
import Checkbox from '@lib-components/Checkbox';
import { EventDataBuilder, EventType } from '@app/components-lib/components/Analytics';
import AnalyticsButton from '@components/Analytics/AnalyticsButton';
import useFirePageLoadEvents from '@hooks/useFirePageLoadEvents';
import { cancelationPagesAnalyticsNames } from '@app/components-lib/components/Analytics';
import Markdown from '@components/Markdown';
import PreviewOrderList from '../PreviewOrderList';
import PreviewSummary from '../PreviewSummary';
import ScreenStepHeader from '../StepScreenHeader';
import CircularProgress from '@mui/material/CircularProgress';

type Props = { onContinue: () => void } & NavigationProp;
const PreviewOrder = ({ Navigation, onContinue }: Props) => {
  const [agree, setAgree] = useState(false);
  const {
    currencyLabel,
    reviewOrder,
    paymentMethod: paymentMethodLabel,
    billingAddress,
    reviewOrder: reviewOrderLabel,
    subscriptionSummary,
    totalAmount,
    totalTax,
    subTotal,
    previewOrderAgree,
    orderPreviewLegal,
    paymentDate,
    subscribeNow,
    refund,
    refundTaxLabel,
    refundDueLabel,
  } = useCancelLabels();

  const { data: account } = useAccount();
  const { data: paymentMethod } = usePaymentMethod();

  const { preview, step } = useCancelMachineContext();

  useFirePageLoadEvents({
    customer_flow: 'cancelation',
    pageName: cancelationPagesAnalyticsNames.get(step),
    pageType: reviewOrder,
    subsection: 'Cancelation > Review Order',
  });

  const orderIsLoading = useIsMutating({ mutationKey: ['createOrder'] });
  return (
    <Stack>
      <ScreenStepHeader>{reviewOrder}</ScreenStepHeader>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Stack mb={2} spacing={1}>
        <h3>
          <b>{paymentMethodLabel}</b>
        </h3>
        <Typography>{paymentMethod?.cardHolderName}</Typography>
        <Typography>{paymentMethod?.cardNumber}</Typography>
        <Typography>{paymentMethod?.cardType}</Typography>
      </Stack>
      <Stack mb={2} spacing={1}>
        <h3>
          <b>{billingAddress}</b>
        </h3>
        <Typography>
          {account?.userName?.fathersName} {account?.userName?.givenName}{' '}
          {account?.userName?.mothersName && account?.userName?.mothersName}
        </Typography>
        <Typography>
          {account?.billingAddress?.unit} {account?.billingAddress?.street}{' '}
        </Typography>
        <Typography>
          {account?.billingAddress?.city} {account?.billingAddress?.state} {account?.billingAddress?.zip}{' '}
        </Typography>
      </Stack>
      <h1>{reviewOrderLabel}</h1>
      <Divider sx={{ mt: 2, mb: 2 }} />

      <PreviewOrderList />

      <PreviewSummary
        currencyLabel={currencyLabel}
        labels={{
          subscriptionSummary,
          subTotal,
          refund,
          refundTaxLabel,
          totalTax,
          refundDueLabel,
          totalAmount,
          paymentDate,
        }}
        preview={preview}
      >
        <Grid xs={12} mt={5}>
          <Typography variant="body1">{orderPreviewLegal}</Typography>
        </Grid>
      </PreviewSummary>

      <Stack direction={'row'} alignItems={'flex-start'} mb={3}>
        <Checkbox
          sx={{
            svg: {
              width: '1.5rem',
              height: '1.5rem',
              borderRadius: 'unset',
              border: '1px solid',
              borderColor: (theme) => theme.palette.grey.A700,
            },
          }}
          checked={agree}
          onChange={(e) => setAgree(e.target.checked)}
        />
        <Markdown>{previewOrderAgree}</Markdown>
      </Stack>
      <Box>
        <AnalyticsButton
          startIcon={orderIsLoading ? <CircularProgress color="secondary" size={20} /> : null}
          sx={(theme) => ({
            width: '100%',
            mb: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
              mr: theme.spacing(1),
              mb: 0,
              width: 'auto',
            },
          })}
          disabled={!agree || !!orderIsLoading}
          onClick={onContinue}
          analyticsEvent={new EventDataBuilder(EventType.CancelationClick).withArgs({
            text: subscribeNow,
            pageName: step,
          })}
        >
          {subscribeNow}
        </AnalyticsButton>
        {Navigation && <Navigation />}
      </Box>
    </Stack>
  );
};

export default PreviewOrder;
