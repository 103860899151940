import { Environment } from '@cv/portal-common-lib/ajax/constants';
import snakeCase from 'lodash/snakeCase';
import { getCookie } from '@utils/cookies';
import { parseSearchParams } from '@utils/urlSearchParams';
import * as envs from './envs';

// THIS "just" needs to do a AJAX request to config/config.txt
// and then "use" it

export const get = <T extends unknown>(key: string, defaultValue: T | null = null): T | null => {
  const config = typeof CONFIG !== 'undefined' ? CONFIG : {};
  if (key in config) {
    return config[key] as T;
  }

  // This needs to be refactored later as part of config cleanup as it should not be part of get()
  const { host } = window.location;
  const tagKey = `CONFIG_TAG_${snakeCase(host).toUpperCase()}`;
  const customEnvKey = `${config[tagKey]}_ENV`;
  const env = `${(config[customEnvKey] || config.ENV)?.toLowerCase()}Config` as keyof typeof envs;

  if (env in envs && key in envs[env]) {
    return envs[env][key as keyof (typeof envs)[typeof env]] as T;
  }

  return defaultValue;
};

export const getOemName = (prefix = 'CONTENTFUL_TAG'): string => {
  const { host } = window.location;
  const key = `${prefix}_${snakeCase(host).toUpperCase()}`;
  return get<string>(key, get<string>('CONTENTFUL_TAG_UNKNOWN')) || '';
};

export const getRawTenantId = () => {
  const [params] = parseSearchParams(location.search);
  const tenantId = getCookie('b');
  return tenantId || params.tenantId || getOemName();
};

export const getTenantId = () => {
  return getRawTenantId().toLowerCase();
};

export const getEnvironmentEnum = () => {
  const serviceEnv = get<keyof typeof Environment>('SERVICE_ENVIRONMENT') || Environment.LOCAL;
  const env = Environment[serviceEnv];
  if (env !== undefined) {
    return env;
  }
  return Environment.LOCAL;
};

export const getOemValueWithConfigTag = (key: string) => {
  return get<string>(`${getOemName('CONFIG_TAG').trim()}_${key}`);
};

export const getOemValue = (key: string) =>
  getOemValueWithConfigTag(key) || get<string>(`${getOemName().trim()}_${key}`) || get<string>(key) || '';

export const getOemValueBySuffix = (key: string, suffix = '') =>
  getOemValue(`${key}_${suffix.toUpperCase()}`) || getOemValue(key);

export default {
  get,
  getOemName,
  getRawTenantId,
  getTenantId,
  getEnvironmentEnum,
  getOemValue,
  getOemValueBySuffix,
};
