import { useEffect } from 'react';
import config from '@config/config';
import { ROOT_EVENTS } from '../../reducers';
import { useDispatch } from 'react-redux';
import { EventDataBuilder, EventType, sendAnalyticsEvent } from '@lib-components/Analytics';
import { useApi } from '@api';

const Logout = (): null => {
  const api = useApi();
  const dispatch = useDispatch();
  useEffect(() => {
    sendAnalyticsEvent(
      new EventDataBuilder(EventType.UserSignedOut).withArgs({ userId: api.storeService.getUserId() }),
    );
    dispatch({ type: ROOT_EVENTS.DESTROY_SESSION });
    console.log('sxm logout', config.getOemValue('LOGOUT_URL'));
    window.location.assign(config.getOemValue('LOGOUT_URL'));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default Logout;
