import React, { forwardRef, useMemo, useState } from 'react';
import ServiceItem from '@components/Cancellation/ServiceItem';
import NavigationProp from '@customTypes/NavigationProp';
import { CapablePackage } from '@customTypes/subscription';
import { VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import useMediaQuery from '@hooks/useMediaQuery';
import { Collapse, Stack, Typography, useTheme } from '@mui/material';
import useFirePageLoadEvents from '@hooks/useFirePageLoadEvents';
import { cancelationPagesAnalyticsNames } from '@app/components-lib/components/Analytics';
import AnalyticsButton from '@components/Analytics/AnalyticsButton';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useCancelLabels } from '../../CancelLabelsContext';
import styles from '../../Cancellation.module.css';
import { getPackagesHeader, getPackagesSubHeader, getSortedServices } from './allBenefitsUtils';
import ScreenStepHeader from '../../StepScreenHeader';
import { CancelFlow } from '@components/Cancellation/CancelSubscriptionStateMachine';
import { EventDataBuilder, EventType } from '@app/components-lib/components/Analytics';

export type AllBenefitsProps = {
  selectedPackages: CapablePackage[];
  step: CancelFlow;
} & NavigationProp;

const AllBenefits = forwardRef(({ selectedPackages, Navigation, step }: AllBenefitsProps, _ref) => {
  const {
    benefits,
    andLabel,
    paidOnly,
    trialOnly,
    trialPaidTogether,
    seeCompleteList,
    hideCompleteList,
    defaultShortDescription,
    defaultLongDescription,
    servicesHierarchy,
    descriptions,
    assets,
  } = useCancelLabels();

  useFirePageLoadEvents({
    customer_flow: 'cancelation',
    pageName: cancelationPagesAnalyticsNames.get(step),
    pageType: benefits,
    subsection: 'Cancelation > All Benefits',
  });

  const [isListExpanded, setIsListExpanded] = useState(false);

  const capableServices = selectedPackages.reduce<VehicleServiceDetails[]>(
    (allServices, { capableServices = [] }) => [...allServices, ...capableServices],
    [],
  );
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.up('xs'));

  const lengthToShow = isMobileView ? 3 : 4;

  const reorderedServices = useMemo(() => {
    return getSortedServices(servicesHierarchy, capableServices).slice(0, lengthToShow);
  }, [capableServices, servicesHierarchy, lengthToShow]);

  const handleExpandList = () => {
    setIsListExpanded((isExpanded) => !isExpanded);
  };

  const packagesHeader = getPackagesHeader({ selectedPackages, andLabel });
  const subHeader = getPackagesSubHeader({ selectedPackages, paidOnly, trialOnly, trialPaidTogether });

  return (
    <Stack component="div" ref={_ref as React.RefObject<HTMLDivElement>}>
      <ScreenStepHeader>{benefits}</ScreenStepHeader>
      <div className={styles['benefits-container']}>
        <h4 className={styles['all-names-of-packages']}>{packagesHeader}</h4>
        <h5 className={styles['all-terms-of-packages']}>{subHeader}</h5>
      </div>

      <Collapse in={isListExpanded}>
        {selectedPackages.map((p, index) => {
          const packageServices = getSortedServices(servicesHierarchy, p.capableServices);

          return (
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 8 }}>
              <Grid xs={8} mt={index !== 0 ? 1 : 0}>
                <Typography variant="h5" fontWeight="bold">
                  {p.marketingName}
                </Typography>
                <Typography variant="body1">{p.longDescription}</Typography>
              </Grid>

              {packageServices.map((_service) => (
                <Grid xs={8} sm={4} key={_service.vehicleServiceId}>
                  <ServiceItem
                    service={_service}
                    assets={assets}
                    descriptions={descriptions}
                    defaultShortDescription={defaultShortDescription}
                    defaultLongDescription={defaultLongDescription}
                  />
                </Grid>
              ))}
            </Grid>
          );
        })}
      </Collapse>
      <Collapse in={!isListExpanded}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 8 }}>
          {reorderedServices.map((_service) => (
            <Grid xs={8} sm={4} key={_service.vehicleServiceId}>
              <ServiceItem
                service={_service}
                assets={assets}
                descriptions={descriptions}
                defaultShortDescription={defaultShortDescription}
                defaultLongDescription={defaultLongDescription}
              />
            </Grid>
          ))}
        </Grid>
      </Collapse>

      <AnalyticsButton
        className={styles['see-complete-list-button']}
        variant="text"
        onClick={handleExpandList}
        analyticsEvent={new EventDataBuilder(EventType.CancelationClick).withArgs({
          text: isListExpanded ? hideCompleteList : seeCompleteList,
          pageName: step,
        })}
      >
        {isListExpanded ? hideCompleteList : seeCompleteList}
      </AnalyticsButton>
      {Navigation && <Navigation />}
    </Stack>
  );
});

export default AllBenefits;
