import React, { ReactNode } from 'react';
import { Container, ContentCenter, LineSeparator } from '../../styled-components/globalStyles';
import { Divider } from './styles';
import Button from '../../components/Button';
import Spinner from '../../components/Spinner';
import AttWifi from '../AttWifi';
import { AuthCodeFormContent, ContentfulAssets, SubscriptionProps, WifiWebContent } from '../Subscription';
import OtpFormConnector from '../../components/OtpForm/OtpFormConnector';
import SuccessfulMessage from '@lib-components/SuccessfulMessage';
import Message from '@lib-components/Message';
import { useFlowMachineContextContent } from '../../Router/RouterContext';
import Alert from '@mui/material/Alert';
import { AlertTitle } from '@mui/material';

interface ConfirmOrderContainer {
  loading?: boolean;
  hasPackageSubscriptions: boolean;
  hasEligiblePackages: boolean;
  confirmOrderHeaderLabel: string;
  doneButtonLabel: string;
  header: string;
  body: string;
  contactCustomerCareHeader: string;
  contactCustomerCareBody: string;
  subscriptionProps: SubscriptionProps;
  assets: ContentfulAssets;
  wifiWebContent: WifiWebContent;
  showOtpForm: boolean;
  authCodeFormContent: AuthCodeFormContent;
  isAuthStatusError: boolean;
  children: ReactNode;
  onSubscriptionCompletion: () => void;
  showDesignatedDealerMessage: boolean;
}

export const ConfirmOrder: React.FC<ConfirmOrderContainer> = ({
  loading,
  hasPackageSubscriptions,
  hasEligiblePackages,
  confirmOrderHeaderLabel,
  doneButtonLabel,
  header,
  body,
  contactCustomerCareHeader,
  contactCustomerCareBody,
  subscriptionProps,
  assets,
  wifiWebContent,
  showOtpForm,
  onSubscriptionCompletion,
  authCodeFormContent,
  isAuthStatusError,
  children,
  showDesignatedDealerMessage = false,
}) => {
  const { designatedRefundDealerMessage, designatedRefundDealerMessageHeader } = useFlowMachineContextContent();

  return (
    <Container>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {showDesignatedDealerMessage && (
            <Alert severity="warning">
              <AlertTitle>{designatedRefundDealerMessageHeader}</AlertTitle>
              {designatedRefundDealerMessage}
            </Alert>
          )}
          {!hasEligiblePackages && (
            <ContentCenter>
              <Message
                type="warning"
                header={contactCustomerCareHeader}
                body={contactCustomerCareBody}
                assets={assets}
              />
              <Divider />
            </ContentCenter>
          )}
          {hasPackageSubscriptions && (
            <>
              <SuccessfulMessage
                assets={assets}
                headerText={confirmOrderHeaderLabel}
                subheaderText={header}
                bodyText={body}
              />
              <Divider />
            </>
          )}
          {showOtpForm && (
            <>
              <OtpFormConnector
                subscriptionProps={subscriptionProps}
                isAuthStatusError={isAuthStatusError}
                skipAction={onSubscriptionCompletion}
                authCodeFormContent={authCodeFormContent}
                assets={assets}
              />
              <Divider />
            </>
          )}
          <AttWifi wifiWebContent={wifiWebContent} subscriptionProps={subscriptionProps} assets={assets} />
          {children}
          <Button disabled={loading} onClick={onSubscriptionCompletion} fullWidth>
            {doneButtonLabel}
          </Button>
        </div>
      )}
    </Container>
  );
};
