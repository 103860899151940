import React from 'react';
import clsx from 'clsx';

import { Button } from '@components-lib';

import styles from './VinStatus.module.css';

type VinStatusProps = {
  vin: string;
  isActive: boolean;
  labelTitle: string;
  labelVinActiveStatus: string;
  labelVinActiveDescription: string;
  labelVinInactiveStatus: string;
  labelVinInactiveDescription: string;
  labelAccountInactive: string;
  labelVin: string;
  labelStatus: string;
  labelBack: string;
  labelUnlink: string;
  labelEnroll: string;
  onCancel: () => void;
  onOk: () => void;
};

const VinStatus = ({
  vin,
  isActive,
  labelTitle,
  labelVinActiveStatus,
  labelVinInactiveStatus,
  labelVinActiveDescription,
  labelVinInactiveDescription,
  labelAccountInactive,
  labelVin,
  labelStatus,
  labelBack,
  labelUnlink,
  labelEnroll,
  onCancel,
  onOk,
}: VinStatusProps) => {
  return (
    <div className={styles.container}>
      <h1>{labelTitle}</h1>
      <p>{isActive ? labelVinActiveDescription : labelVinInactiveDescription}</p>
      <div className={styles.data}>
        <div className={clsx(styles.row, styles.head)}>
          <div>{labelVin}</div>
          <div>{labelStatus}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.vin}>{vin}</div>
          <div className={clsx(styles.status, { [styles.active]: isActive })}>
            {isActive ? labelVinActiveStatus : labelVinInactiveStatus}
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button variant="outlined" onClick={onCancel}>
          {labelBack}
        </Button>
        <Button variant="contained" onClick={onOk}>
          {isActive ? labelUnlink : labelEnroll}
        </Button>
      </div>
    </div>
  );
};

export default VinStatus;
