import './MapWindow.css';

import { FAIL, Label_Message, RemoteCommand, lockStatusValues } from './constants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { checkServicesSubscribed, isServiceSubscribed } from '@utils/checkIsServiceSubscribed';
import { ContentfulImage } from '@cv/webframework-react-components';

import { useAnalytics } from '@cv/webframework-react-components';
import { APIResponse } from '@cv/portal-common-lib/ajax/models';
import { CreatorForm } from '@components/EntryCreator';
import { EngineState } from '@cv/portal-rts-lib/engines/enums';
// THESE IMAGE IMPORTS ARE TEMP UNTIL WE HAVE ACCESS TO THE REAL IMAGES
import { LockType } from '@cv/portal-rts-lib/health/enums';
import MapCountDownTimer from './MapCountDownTimer';
import ModalContainer from '@components/ModalContainer';
import { SetupPinAndSecurityQuestionsRequest } from '@cv/portal-idm-lib/security-questions-pin/models';
import { Status } from '@cv/portal-rts-lib/doors/enums/doors.enum';
import { UserSecurityQuestionObject } from '@cv/portal-idm-lib/models';
import { formatDate } from '@utils/format-date';
import gasPumpStatus from '../../assets/gas-pump-status.png';
import lockStatus from '../../assets/lock-status.png';
import lockUnavailable from '@assets/lock-unavailable-status.svg';
import refreshIcon from '../../assets/refresh.svg';
import { setLoadingStatus } from '@redux/actions/loading';
import { setUserPinStatus } from '@redux/actions';
import unlockStatus from '../../assets/unlock-status.svg';
import WarningIcon from '@assets/x-status.png';
import { FaTimes } from 'react-icons/fa';
import { useApi } from '@api';
import { useDispatch, useSelector } from 'react-redux';
import useFetchVehicleHealth from '@hooks/useFetchVehicleHealth';
import useToggle from '@hooks/useToggle';
import checkWarningStatus from '@utils/warning-status-checker';
import { PollingStatus } from '@utils/polling';
import { useVehicleSelector, useVehicleHealthSelector } from '@redux/selectors/vehicle';
import { useConvertDistance } from '@utils/metrics';
import { generateAssetsObjects } from '@utils/assets';
import Button from '@app/components-lib/components/Button';
import { useTheme } from '@mui/material';

type ActionIconsRequest = {
  Img: React.ComponentType<Partial<HTMLElement>>;
  onClick: () => void;
  classes: string;
  disabled: string;
  callBackClass: string;
  selectedElement: string;
};

type MapWindowHomeProps = {
  labels: {
    ContactingVehicle: string;
    InitiatingService: string;
    ServiceCompleted: string;
    UnabletoContactVehicle: string;
    RemoteAccess: string;
    Unabletoconnecttonetwork: string;
    Refresh: string;
    Updated: string;
    Left: string;
    timeLeft: string;
    min: string;
    pressToStop: string;
    ConfirmationModalNo: string;
    ConfirmationModalYes: string;
    ConfirmationModalHeader: string;
    UnlockConfirmationMessage: string;
    LockConfirmationMessage: string;
    StartConfirmationMessage: string;
    StopConfirmationMessage: string;
    unavailable: string;
    MakeAndModel: string;
    LockedLabel: string;
    UnlockedLabel: string;
    performMaintenance: string;
    DOOR_LOCK_UNLOCK: string;
    DOOR_UNLOCK: string;
    HORN_LIGHT: string;
    START_STOP_ENGINE: string;
    REMOTE_VEHICLE_STATUS: string;
  };
  securityPinContent: {
    header: string;
    description: string;
    securityPinForm: Object;
    buttonLabel: string;
  };
  listOfIcons: ContentfulFile[];
  resetMap: () => void;
};
type SetupPinData = {
  newPinField: string;
  securityQuestion: string;
  securityAnswer: string;
};

function MapWindow({ labels, securityPinContent, listOfIcons, resetMap }: MapWindowHomeProps) {
  const { DOOR_LOCK_UNLOCK, DOOR_UNLOCK, HORN_LIGHT, START_STOP_ENGINE, REMOTE_VEHICLE_STATUS } = labels;
  const remoteServices = {
    DOOR_LOCK_UNLOCK,
    DOOR_UNLOCK,
    HORN_LIGHT,
    START_STOP_ENGINE,
  };
  const listOfAccets = useMemo(() => generateAssetsObjects(listOfIcons), [listOfIcons]);
  const getIconById = useCallback((id: string) => listOfAccets[id] ?? {}, [listOfAccets]);

  const icons = {
    hornLight: {
      Img: getIconById('hornLight'),
      classes: '',
      disabled: '',
      callBackClass: '',
      selectedElement: '',
    },
    doorLock: {
      Img: getIconById('lock'),
      classes: '',
      disabled: '',
      callBackClass: '',
      selectedElement: '',
    },
    light: {
      Img: getIconById('light'),
      classes: '',
      disabled: '',
      callBackClass: '',
      selectedElement: '',
    },
    doorUnlock: {
      classes: '',
      Img: getIconById('unlock'),
      disabled: '',
      callBackClass: '',
      selectedElement: '',
    },
    startStopEngine: {
      classes: '',
      Img: getIconById('startStop'),
      disabled: '',
      callBackClass: '',
      selectedElement: '',
    },
    stopEngine: {
      classes: '',
      Img: getIconById('startStop'),
      disabled: '',
      callBackClass: '',
      selectedElement: '',
    },
  };
  const api = useApi();
  const userVehicleInfo = api.storeService.getUserVehicleInfo();
  const dispatch = useDispatch();
  const [actionIcons, setIconsStatus] = useState(icons);
  const [validationMessage, setValidationMessage] = useState('');
  const vehicle = useVehicleSelector();
  const { data: vehicleHealth } = useVehicleHealthSelector();
  const getOverallLockStatus = vehicleHealth?.locks?.find((item) => item.type === LockType.Overrall_Lock);
  const { isPinConfigured } = useSelector(({ userReducer }) => userReducer);
  const { fetchVehicleHealth } = useFetchVehicleHealth();
  const [showSecurityPinModal, toggleSecurityPinModal] = useToggle<boolean>(false);
  const [showSecurityPinForm, toggleSecurityPinForm] = useState<boolean>(false);
  const [remoteCmdToExecute, setRemoteCmdToExecute] = useState<RemoteCommand>();
  const [showRemoteCmdConfirmationModal, toggleShowRemoteCmdConfirmationModal] = useToggle<boolean>(false);
  const [isCheckWarningStatus, setIsCheckWarningStatus] = useState<boolean>(false);
  const [closeWarningModal, setCloseWarningModal] = useState(false);
  const timerWindow = useSelector(({ mapReducer }) => mapReducer.timerWindow);
  const { trackEvent } = useAnalytics();
  const convertDistance = useConvertDistance();
  const theme = useTheme();

  const setTimerWindow = (timerWindow: boolean) => {
    dispatch({ type: 'SET_TIMER_WINDOW', timerWindow });
  };

  const renderWarningBanner = () => {
    return (
      <div className="warning-banner">
        <img className="warning-banner-icon" src={WarningIcon} alt="Warning icon" />
        {/* add label  */}
        <span className="warning-banner-text">{labels.performMaintenance}</span>
        <FaTimes className="warning-banner-cross" onClick={() => setCloseWarningModal(!closeWarningModal)} />
      </div>
    );
  };

  const allRemoteServiceAvailable = checkServicesSubscribed(Object.values(remoteServices), vehicle?.activeServices);

  const onlyLockUnlock = allRemoteServiceAvailable.every(
    (availableService) =>
      availableService === remoteServices.DOOR_UNLOCK || availableService === remoteServices.DOOR_LOCK_UNLOCK,
  );

  useEffect(() => {
    resetMap();
    if (isServiceSubscribed([REMOTE_VEHICLE_STATUS], vehicle?.activeServices)) {
      fetchVehicleHealth({ allowBackgroundUpdate: true });
    }
  }, []);

  useEffect(() => {
    if (isServiceSubscribed([REMOTE_VEHICLE_STATUS], vehicle?.activeServices)) {
      setIsCheckWarningStatus(!!checkWarningStatus(vehicleHealth?.mil));
    }
  }, [vehicleHealth]);

  let state: any;
  let index: any;

  const handleRemoteActions = (value: RemoteCommand) => {
    index = value;
    state = { ...actionIcons };
    Object.keys(state).forEach((key) => {
      if (key === value) {
        state[value].classes = 'map-window-container_loader';
      } else {
        state[key].disabled = 'map-window-container_disable';
        state[key].selectedElement = 'map-window-container_disable_div';
      }
    });
    setIconsStatus(state);
    remoteHandler(value);
  };

  const remoteCmdExecutionHandler = (value: RemoteCommand) => {
    state = { ...actionIcons };
    //Save the command to execute after pin is configured or user confirms the operation
    setRemoteCmdToExecute(value);
    Object.keys(state).forEach((key) => {
      if (key != value) {
        if (
          value === RemoteCommand.STOP_ENGINE &&
          (key === RemoteCommand.STOP_ENGINE || key === RemoteCommand.START_STOP_ENGINE)
        ) {
          state[key].disabled = '';
        } else {
          state[key].disabled = `map-window-container_disable${
            key === RemoteCommand.START_STOP_ENGINE ? '_center' : ''
          }`;
        }
        state[key].selectedElement = 'map-window-container_disable_div';
      }
    });
    setIconsStatus(state);
    // If Pin is not configured, prompt the user to configure pin and save the command to execute after pin is configured
    if (!isPinConfigured) {
      //Fetch the security questions from Api to replace static values from contentful
      populateSecurityQuestions();
      toggleSecurityPinModal(true);
    } else {
      toggleShowRemoteCmdConfirmationModal(true);
    }
  };

  const remoteCmdConfirmationHandler = () => {
    toggleShowRemoteCmdConfirmationModal(false);
    // Adding Loader for current executing remote command Icon
    actionIcons[remoteCmdToExecute].classes = `map-window-container_loader${
      remoteCmdToExecute === RemoteCommand.START_STOP_ENGINE || remoteCmdToExecute === RemoteCommand.STOP_ENGINE
        ? '_center'
        : ''
    }`;
    setIconsStatus(actionIcons);
    remoteHandler(remoteCmdToExecute);
  };
  const remoteCmdCloseHandler = () => {
    setIconsStatus(icons);
    toggleShowRemoteCmdConfirmationModal(false);
  };

  const callBackStatus = (state: any, key: string, res?: string) => {
    const updatedState = { ...state };
    if (res === FAIL) {
      state[key].Img = getIconById('fail');
      state[key].classes = '';
      state[key].callBackClass = 'map-window-container_disable_center_xlock';
    } else {
      if (key === RemoteCommand.START_STOP_ENGINE || key === RemoteCommand.STOP_ENGINE) {
        state[key].callBackClass = 'map-window-container_disable_centerimage_success';
      } else {
        state[key].callBackClass = 'map-window-container_disable__success';
      }
      state[key].Img = getIconById('success');
      state[key].classes = '';
      state[key].selectedElement = 'map-window-container_disable_div_success';
    }
    setIconsStatus(updatedState);
  };

  const remoteHandler = async (remoteCommand: RemoteCommand) => {
    const request = {
      validationCallback,
      successHandlerCallback,
      errorHandlerCallback,
      remoteCommand,
    };
    switch (remoteCommand) {
      case RemoteCommand.DOOR_LOCK:
      case RemoteCommand.DOOR_UNLOCK:
        displayMessage(Label_Message.ContactingVehicle);
        await api.makeRemoteDoorAPICall(request);
        break;
      case RemoteCommand.HORN_LIGHT:
      case RemoteCommand.LIGHT:
        displayMessage(Label_Message.ContactingVehicle);
        await api.makeHornLightsAPICall(request);
        break;
      case RemoteCommand.START_STOP_ENGINE:
      case RemoteCommand.STOP_ENGINE:
        displayMessage(Label_Message.ContactingVehicle);
        updatedTimerValue(remoteCommand);
        await api.makeEngineStartStopCall(request);
        break;
    }
  };

  const displayMessage = (message: string) => {
    switch (message) {
      case Label_Message.ContactingVehicle:
        setValidationMessage(labels.ContactingVehicle);
        break;
      case Label_Message.InitiatingService:
        setValidationMessage(labels.InitiatingService);
        break;
      case Label_Message.UnabletoContactVehicle:
        setValidationMessage(labels.UnabletoContactVehicle);
        break;
      case Label_Message.ServiceCompleted:
        setValidationMessage(labels.ServiceCompleted);
        break;
      case Label_Message.Unabletoconnecttonetwork:
        setValidationMessage(labels.Unabletoconnecttonetwork);
        break;
    }
  };

  const successHandlerCallback = () => {
    displayMessage(Label_Message.ServiceCompleted);
    callBackStatus(actionIcons, index || remoteCmdToExecute);
    setTimeout(() => {
      setValidationMessage('');
      setIconsStatus(icons);
    }, 3000);
  };

  const errorHandlerCallback = (response?: APIResponse<any>) => {
    displayMessage(
      response?.data?.status ? Label_Message.Unabletoconnecttonetwork : Label_Message.UnabletoContactVehicle,
    );
    callBackStatus(actionIcons, index || remoteCmdToExecute, FAIL);
    setTimeout(() => {
      setValidationMessage('');
      setIconsStatus(icons);
    }, 3000);
  };

  const validationCallback = (response: APIResponse<any>) => {
    const { status, requestedState } = response.data?.svcRequests?.[0] || {};

    if (status === Status.SUCCESS) {
      if (requestedState === EngineState.START) {
        setTimerWindow((timerWindow) => !timerWindow);
      }

      return PollingStatus.SUCCESS;
    }

    if (status === Status.FAILED) {
      return PollingStatus.ERROR;
    }

    displayMessage(Label_Message.InitiatingService);

    return PollingStatus.PENDING;
  };

  const setupPin = async (formValues: SetupPinData) => {
    const payload: SetupPinAndSecurityQuestionsRequest['data'] = {
      input: {
        kbaInfo: [
          {
            tenantQuestionId: formValues.securityQuestion,
            answer: formValues.securityAnswer,
          },
        ],
        pin: formValues.newPinField,
      },
    };

    try {
      dispatch(setLoadingStatus(true));
      await api.setupSecurityPin(payload);
      setupPinSuccessHandler();
    } catch (e) {
      setValidationMessage(labels.Unabletoconnecttonetwork);
    } finally {
      dispatch(setLoadingStatus(false));
    }
  };

  const closeHandler = () => {
    setValidationMessage('');
    setIconsStatus(icons);
    toggleSecurityPinModal(false);
    toggleSecurityPinForm(false);
  };

  const setupPinSuccessHandler = () => {
    toggleSecurityPinModal(false);
    toggleSecurityPinForm(false);
    dispatch(setUserPinStatus(true));
    // Adding Loader for current executing remote command Icon
    actionIcons[remoteCmdToExecute].classes = `map-window-container_loader${
      remoteCmdToExecute === RemoteCommand.START_STOP_ENGINE ? '_center' : ''
    }`;
    setIconsStatus(actionIcons);
    remoteHandler(remoteCmdToExecute);
  };
  const updateSecurityQuestions = (securityQuestions: UserSecurityQuestionObject | null) => {
    const securityQuestionsField = securityPinContent?.securityPinForm?.formFields.find(
      (field) => field.fieldName === 'securityQuestion',
    );
    securityQuestionsField.fieldValues = securityQuestions
      ? Object.entries(securityQuestions.tenantQuestions).map(([key, value]) => `${key}::${value}`)
      : [];
  };

  const populateSecurityQuestions = async () => {
    // Repopulate security question list from Contentful
    updateSecurityQuestions(null);
    try {
      const { data: securityQuestions } = await api.getSecurityQuestions();
      updateSecurityQuestions(securityQuestions);
    } catch (e) {}
  };
  const updatedTimerValue = (val: any) => {
    if (val === 0 || val === RemoteCommand.STOP_ENGINE) {
      setTimerWindow(false);
      dispatch({ type: 'SET_MAP_TIMER_VALUE', mapTimerValue: 600 });
    }
  };
  const RenderButton = ({ ...props }: ActionIconsRequest) => {
    return (
      <div className={props.selectedElement}>
        <span className={props.classes}></span>
        <button
          className={`map-window-container__button-row--button
          ${props.disabled}
          ${props.classes ? 'map-window-container__button-row--button_spin' : ''}
          ${props.callBackClass}`}
          onClick={() => props.onClick()}
          style={{
            backgroundColor: theme.palette.portalTheme.headerBackgroundColor,
            border: `2px solid ${theme.palette.text.headerIcons}`,
          }}
        >
          <ContentfulImage
            imageData={props.Img}
            className={
              props.disabled ? 'map-window-container__disabled-image' : 'map-window-container__button-row-disabled'
            }
          />
        </button>
      </div>
    );
  };

  const renderRemoteIcons = () => {
    const { hornLight, doorLock, startStopEngine, stopEngine } = actionIcons;
    return (
      <>
        <div className="map-window-container__button-row">
          {allRemoteServiceAvailable.includes(remoteServices.HORN_LIGHT) && (
            <RenderButton
              {...hornLight}
              onClick={() => {
                trackEvent('ServicesRemote::RemoteHornLights-Clicked');
                handleRemoteActions(RemoteCommand.HORN_LIGHT);
              }}
            />
          )}

          {allRemoteServiceAvailable.includes(remoteServices.DOOR_LOCK_UNLOCK) && (
            <RenderButton
              {...doorLock}
              onClick={() => {
                trackEvent('ServicesRemote::DoorLock-Clicked');
                remoteCmdExecutionHandler(RemoteCommand.DOOR_LOCK);
              }}
            />
          )}
        </div>
        {/* placeholder to save properly design of other icons if start/stop unavailable */}
        {!allRemoteServiceAvailable.includes(remoteServices.START_STOP_ENGINE) && (
          <div className="remote-services-placeholder" />
        )}
        {allRemoteServiceAvailable.includes(remoteServices.START_STOP_ENGINE) && (
          <div
            className={`map-window-container__button-row-center
        ${
          startStopEngine.disabled ||
          stopEngine.disabled ||
          stopEngine?.classes === 'map-window-container_loader_center'
            ? 'map-window-container_disable_center'
            : ''
        }`}
          >
            {startStopEngine.disabled ? (
              ''
            ) : (
              <span className={actionIcons.startStopEngine.classes || actionIcons.stopEngine.classes}></span>
            )}
            <button
              className={`map-window-container__button-row--button-center${timerWindow ? '_timer' : ''}
          ${startStopEngine.callBackClass || stopEngine.callBackClass}`}
              onClick={() => {
                if (!timerWindow) {
                  trackEvent('ServicesRemote::RemoteEngineStart-Clicked');
                } else {
                  trackEvent('ServicesRemote::RemoteEngineStop-Clicked');
                }
                remoteCmdExecutionHandler(!timerWindow ? RemoteCommand.START_STOP_ENGINE : RemoteCommand.STOP_ENGINE);
              }}
              style={{
                backgroundColor: theme.palette.portalTheme.headerBackgroundColor,
                border: `2px solid ${theme.palette.text.headerIcons}`,
              }}
            >
              {startStopEngine.callBackClass || stopEngine.callBackClass ? (
                startStopEngine.callBackClass === 'map-window-container_disable_centerimage_success' ||
                stopEngine.callBackClass === 'map-window-container_disable_centerimage_success' ? (
                  <ContentfulImage imageData={getIconById('success')} />
                ) : (
                  <ContentfulImage imageData={getIconById('fail')} />
                )
              ) : !timerWindow ? (
                <ContentfulImage imageData={getIconById('startStop')} />
              ) : (
                <MapCountDownTimer labels={labels} updatedTimerValue={updatedTimerValue} />
              )}
            </button>
          </div>
        )}
        <div className="map-window-container__button-row">
          {allRemoteServiceAvailable.includes(remoteServices.HORN_LIGHT) && (
            <RenderButton
              {...actionIcons.light}
              onClick={() => {
                trackEvent('ServicesRemote::RemoteLights-Clicked');
                handleRemoteActions(RemoteCommand.LIGHT);
              }}
            />
          )}
          {(allRemoteServiceAvailable.includes(remoteServices.DOOR_UNLOCK) ||
            allRemoteServiceAvailable.includes(remoteServices.DOOR_LOCK_UNLOCK)) && (
            <RenderButton
              {...actionIcons.doorUnlock}
              onClick={() => {
                trackEvent('ServicesRemote::DoorUnLock-Clicked');
                remoteCmdExecutionHandler(RemoteCommand.DOOR_UNLOCK);
              }}
            />
          )}
        </div>
      </>
    );
  };

  const renderRemoteContainer = () => {
    if (allRemoteServiceAvailable.length) {
      if (onlyLockUnlock) {
        return (
          <div className="map-window-container__button-row map-window-container__two_icons">
            <RenderButton
              {...actionIcons.doorLock}
              onClick={() => remoteCmdExecutionHandler(RemoteCommand.DOOR_LOCK)}
            />
            <RenderButton
              {...actionIcons.doorUnlock}
              onClick={() => remoteCmdExecutionHandler(RemoteCommand.DOOR_UNLOCK)}
            />
          </div>
        );
      } else {
        return renderRemoteIcons();
      }
    } else {
      return null;
    }
  };

  const getRemoteCmdConfirmationMessage = () => {
    let confirmationMessage = '';
    switch (remoteCmdToExecute) {
      case RemoteCommand.DOOR_LOCK:
        confirmationMessage = labels?.LockConfirmationMessage;
        break;
      case RemoteCommand.DOOR_UNLOCK:
        confirmationMessage = labels?.UnlockConfirmationMessage;
        break;
      case RemoteCommand.START_STOP_ENGINE:
        confirmationMessage = labels?.StartConfirmationMessage;
        break;
      case RemoteCommand.STOP_ENGINE:
        confirmationMessage = labels?.StopConfirmationMessage;
        break;
    }
    return confirmationMessage;
  };

  const renderLockImages = () => {
    if (lockStatusValues.includes(getOverallLockStatus?.status)) {
      const lockStatusLabel = ['locked', 'Bloqueado'].includes(getOverallLockStatus.status)
        ? labels.LockedLabel
        : labels.UnlockedLabel;
      return (
        <>
          <img
            className="map-window-container__status--gas-image"
            src={
              getOverallLockStatus?.status === lockStatusValues[0] ||
              getOverallLockStatus?.status === lockStatusValues[1]
                ? unlockStatus
                : lockStatus
            }
            alt=""
          />
          <p className="map-window-container__status--gas-text">{lockStatusLabel}</p>
        </>
      );
    } else {
      return (
        <>
          <img className="map-window-container__status--gas-image" src={lockUnavailable} alt="" />
          <p className="map-window-container__status--gas-text">{labels.unavailable}</p>
        </>
      );
    }
  };

  const showWarningBanner = !closeWarningModal && onlyLockUnlock && isCheckWarningStatus;

  const { value: fuelValue, unit: fuelUoM } = convertDistance(
    vehicleHealth?.fuelAutonomy?.value,
    vehicleHealth?.fuelAutonomy?.UoM,
  );

  return (
    <div className="map-window-container" style={{ borderTopColor: theme.palette.primary.main }}>
      <div className="map-window-container__header">
        <p style={{ color: theme.palette.portalTheme.navigationTextColor }}>{userVehicleInfo}</p>
      </div>
      <div className="map-window-container__subheader map-window-container-text-transform">
        <p style={{ color: theme.palette.portalTheme.navigationTextColor }}>{labels?.RemoteAccess}</p>
      </div>
      <div className="map-window-container__subheader map-window-container_bottom_subheader">
        <p style={{ color: theme.palette.portalTheme.navigationTextColor }}>{validationMessage}</p>
      </div>
      {renderRemoteContainer()}
      {showWarningBanner && renderWarningBanner()}
      {isServiceSubscribed([REMOTE_VEHICLE_STATUS], vehicle?.activeServices) && (
        <>
          <div className="map-window-container__status">
            <div className="map-window-container__status--gas">
              <img className="map-window-container__status--gas-image" src={gasPumpStatus} alt="" />
              <p className="map-window-container__status--gas-text">
                <span>
                  <span>
                    {fuelValue} {fuelUoM?.toLowerCase()}
                  </span>{' '}
                  <span>{labels?.Left}</span>
                </span>
              </p>
            </div>
            <div className="map-window-container__status--lock">{renderLockImages()}</div>
          </div>
          <div className="map-window-container__updated">
            <p className="map-window-container__status--gas-text">
              <span>{labels?.Updated}</span> {formatDate(vehicleHealth?.lastUpdatedTime)}
              {' | '}
              <span>{labels?.Refresh}</span>
              <img
                src={refreshIcon}
                className="map-window-container-refresh-icon"
                onClick={() => {
                  trackEvent('ServicesRemote::DataRefresh-Clicked');
                  fetchVehicleHealth();
                }}
              />
            </p>
          </div>
        </>
      )}
      <div className="map-window-security-pin-modal">
        <ModalContainer
          show={showSecurityPinModal}
          header={{ text: securityPinContent?.header, position: 'center' }}
          onCloseHandler={() => closeHandler()}
          size="md"
          height="auto"
          overflowY="visible"
        >
          {!showSecurityPinForm && (
            <div className="text-center">
              <div className="map-window-security-pin-body--info">
                <p> {securityPinContent?.description} </p>
              </div>
              <div>
                <button className="map-window-security-pin-button" onClick={() => toggleSecurityPinForm(true)}>
                  {securityPinContent?.buttonLabel}
                </button>
              </div>
            </div>
          )}
          {showSecurityPinForm && (
            <div className="map-window-security-pin-form">
              <div className="text-center error">
                <p>{validationMessage}</p>
              </div>
              <CreatorForm
                onFormConfirm={setupPin}
                onFormClose={closeHandler}
                {...securityPinContent.securityPinForm}
              />{' '}
            </div>
          )}
        </ModalContainer>
      </div>
      <div className="map-window-security-pin-modal">
        <ModalContainer
          show={showRemoteCmdConfirmationModal}
          header={{ text: labels?.ConfirmationModalHeader, position: 'center' }}
          size="md"
          height="auto"
        >
          <div className="text-center">
            <div className="map-window-security-pin-body--info">
              <p> {getRemoteCmdConfirmationMessage()} </p>
            </div>
            <div>
              <Button className="map-window-security-pin-button" onClick={remoteCmdCloseHandler}>
                {labels?.ConfirmationModalNo}
              </Button>
              <Button className="map-window-security-pin-button" onClick={remoteCmdConfirmationHandler}>
                {labels?.ConfirmationModalYes}
              </Button>
            </div>
          </div>
        </ModalContainer>
      </div>
    </div>
  );
}

export default MapWindow;
