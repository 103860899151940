import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { startCase } from 'lodash';
import { isServiceSubscribed } from '@utils/checkIsServiceSubscribed';
import { RootState } from '@app/reducers';
import { IVehicle } from '@redux/actions';
import { Page, Path } from '@customTypes/Navigation';
import useVehicle from '@api/queries/useVehicle';

const useComputePaths = (pagesList: Page[]) => {
  const { data: vehicle } = useVehicle();

  return useCallback(() => {
    const paths = extractNavigationPages(pagesList, vehicle);
    const currentPath = findCurrentPath(paths, window.location.pathname);
    return { paths, currentPath };
  }, [pagesList, vehicle]);
};

export default useComputePaths;

function findCurrentPath(paths: Path[], pathname: string) {
  const currentPath = pathname?.substring(1);
  const defaultPage: Path = {
    page: currentPath,
    url: pathname,
    hiddenInNavigation: true,
    label: startCase(currentPath),
    services: [],
    details: null,
    authorised: true,
  };

  const currentPage = paths
    .reduce<Path[]>((allPaths, path) => [...allPaths, ...(path.subPages || []), path], [])
    .filter((path) => path.url === pathname && !path.disabled)
    .find((page) => !page.subPages);

  return currentPage || defaultPage;
}

type SubPages = {
  subPages: Path[];
};

type UnaccessibleSubPages = {
  disabled: true;
};

function extractNavigationPages(contentfulPages: Page[], vehicle: IVehicle) {
  const handleSubPages = (contentfulPage: Page): SubPages | UnaccessibleSubPages => {
    const subPages = contentfulPage.contentSections
      .filter(isPage)
      .filter((page) => !shouldBeNotAccessible(page, vehicle))
      .reduce<Path[]>((allSubPages, currentSubPage) => [...allSubPages, ...turnIntoNavigationPage(currentSubPage)], []);

    return subPages.length > 0 ? { subPages } : { disabled: true };
  };

  return contentfulPages.reduce<Path[]>((allPages, contentfulPage) => {
    if (shouldBeNotAccessible(contentfulPage, vehicle)) {
      return allPages;
    }

    let navigationPage = turnIntoNavigationPage(contentfulPage);
    if (hasSubPages(contentfulPage)) {
      const subPages = handleSubPages(contentfulPage);
      navigationPage = navigationPage.map((currentPage) => ({ ...currentPage, ...subPages }));
    }
    return [...allPages, ...navigationPage];
  }, []);
}

function shouldBeNotAccessible(page: Page, vehicle: IVehicle) {
  return (
    !page.showNoServicesError &&
    page.services?.length > 0 &&
    !isServiceSubscribed(page.services, vehicle?.activeServices)
  );
}

function turnIntoNavigationPage(contentfulPage: Page) {
  const pagePath = contentfulPage.path?.trim() || '';
  const navigationPage: Path = {
    page: '',
    hiddenInNavigation: contentfulPage.hiddenInNavigation,
    services: contentfulPage.services,
    details: contentfulPage,
    authorised: contentfulPage.authorised,
  };

  const labels = contentfulPage.label?.split(',').map((lbl) => lbl.trim());
  return pagePath.split(',').map((path, index) => {
    const possiblePath = path.trim();
    const label = labels?.[index];
    return {
      ...navigationPage,
      page: possiblePath,
      url: takeCorrectPath(path.trim()),
      label,
    };
  });
}

export function takeCorrectPath(possiblePath?: string) {
  if (!possiblePath) {
    return '';
  }
  return possiblePath.includes('/') ? possiblePath : `/${possiblePath}`;
}

function isPage({ componentType }: { componentType: string }) {
  return componentType === 'portalTwoColumnPage';
}

function hasSubPages(page: Page) {
  return Array.isArray(page.contentSections) && page.contentSections.some(isPage);
}
