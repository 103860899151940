// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ns7afKbFSfa8mlSZ_aso {
  display: grid;
  grid-template-rows: auto;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  row-gap: 1rem;
  align-items: center;
}

.xYVH7n8glEY_bRRy8NPy {
  font-weight: bold;
}

.XN6vpxAloNHZJCS0KCj0 {
  color: rgb(52, 52, 52);
  font-size: var(--theme-label-font-size);
}

.kqNcH7Xwbn7IBBFIg_xX {
  margin-top: 1rem;
  padding-bottom: 1rem;
}

.ozmXKtH_fG0rOtJEWLrA {
  color: var(--theme-color-success);
}

.sA9hrWQ9mc02l9ldhkpA {
  height: 0.7em;
  width: 0.7em;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.o3WYDjej39TS7TaD8hnc {
  background-color: var(--theme-color-success);
}

.NVstvV7et9Zs6g_FPXaT {
  background-color: rgb(52, 52, 52);
}

.Lc00foi7oo2NA8tO6Brz,
.Lc00foi7oo2NA8tO6Brz:hover {
  cursor: pointer;
  color: var(--theme-color-primary);
}

.zdXNA3RjMrLW5eNQi5uq {
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  min-width: 125px;
}

.kmIDGW0AkuB3hMO0L7zI {
  grid-template-columns: 1fr 1fr;
}

.QfNWNgrpCkR2QyXr2mxs {
  margin: 0 auto;
  max-width: 500px;
}
`, "",{"version":3,"sources":["webpack://./src/components/VinStatusPage/VinStatusPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wBAAwB;EACxB,qBAAgB;OAAhB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,uCAAuC;AACzC;;AAEA;EACE,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,oBAAoB;AACtB;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,iCAAiC;AACnC;;AAEA;;EAEE,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".Grid {\n  display: grid;\n  grid-template-rows: auto;\n  column-gap: 1rem;\n  row-gap: 1rem;\n  align-items: center;\n}\n\n.GridHeader {\n  font-weight: bold;\n}\n\n.GridBody {\n  color: rgb(52, 52, 52);\n  font-size: var(--theme-label-font-size);\n}\n\n.GridSeperation {\n  margin-top: 1rem;\n  padding-bottom: 1rem;\n}\n\n.Active {\n  color: var(--theme-color-success);\n}\n\n.Dot {\n  height: 0.7em;\n  width: 0.7em;\n  border-radius: 50%;\n  display: inline-block;\n  vertical-align: middle;\n  margin-right: 0.4rem;\n}\n\n.DotActive {\n  background-color: var(--theme-color-success);\n}\n\n.DotNotActive {\n  background-color: rgb(52, 52, 52);\n}\n\n.Anchor,\n.Anchor:hover {\n  cursor: pointer;\n  color: var(--theme-color-primary);\n}\n\n.Button {\n  width: fit-content;\n  white-space: nowrap;\n  min-width: 125px;\n}\n\n.grid-column-2-mobile {\n  grid-template-columns: 1fr 1fr;\n}\n\n.CardContainer {\n  margin: 0 auto;\n  max-width: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Grid": `Ns7afKbFSfa8mlSZ_aso`,
	"GridHeader": `xYVH7n8glEY_bRRy8NPy`,
	"GridBody": `XN6vpxAloNHZJCS0KCj0`,
	"GridSeperation": `kqNcH7Xwbn7IBBFIg_xX`,
	"Active": `ozmXKtH_fG0rOtJEWLrA`,
	"Dot": `sA9hrWQ9mc02l9ldhkpA`,
	"DotActive": `o3WYDjej39TS7TaD8hnc`,
	"DotNotActive": `NVstvV7et9Zs6g_FPXaT`,
	"Anchor": `Lc00foi7oo2NA8tO6Brz`,
	"Button": `zdXNA3RjMrLW5eNQi5uq`,
	"grid-column-2-mobile": `kmIDGW0AkuB3hMO0L7zI`,
	"CardContainer": `QfNWNgrpCkR2QyXr2mxs`
};
export default ___CSS_LOADER_EXPORT___;
