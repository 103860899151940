import config from '@config/config';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { userCreateUrls } from '@cv/portal-idm-lib/user/user-create/enums';
import { UserCreatePayload, UserCreate } from '@cv/portal-idm-lib/user/user-create/models';
import { userCreate } from '@cv/portal-idm-lib/user/user-create';
import { formatUserData } from '../formatters/';

type CreateAccountArgs = {
  data: UserCreatePayload;
  accessToken: string;
  locale: string;
};

export default class AccountCreationService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  createUser({ data, accessToken, locale }: CreateAccountArgs) {
    let API_BASE_URL = 'IDM_API_PROXY_BASE_URL';
    const sourceChannel = config.getOemValue('SOURCE_CHANNEL');

    const payload: UserCreate = {
      data,
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
      },
    };
    if (sourceChannel === 'CWP') {
      API_BASE_URL = 'SHIM_IDM_API_BASE_URL';
      payload.headers['CV-Tenant-Id'] = data.tenantId;
    }

    const idmApiProxyBaseUrl = config.get<string>(API_BASE_URL);
    if (idmApiProxyBaseUrl) {
      const { userCreate } = userCreateUrls;

      const url = new URL(userCreate, idmApiProxyBaseUrl);
      payload.url = url.toString();
    }

    return userCreate(payload).then((response) => ({ data: formatUserData(response.data) }));
  }
}
