import React, { useEffect, useRef, useCallback, useState } from 'react';

import { clearMapBoundaries } from '@utils/clearMapEntities';
import useScript from '@hooks/useScript';
import useLocaleSelector from '@redux/selectors/locale';

import { GEOFENCE_RADIUS_MIN, GEOFENCE_RADIUS_MAX } from './constants';

import carIcon from '../../assets/Pin_select.png';
import bluePin from '../../assets/pin.png';

import './MapWindow.css';
import { useSelector } from 'react-redux';
import { RootState } from '@app/reducers';
import { distanceInUnits, getLongUnit, milesCountries, UpperCaseLongUnits } from '@utils/metrics';
import config from '@config/config';
import useGeolocation from '@hooks/useGeolocation';
import { Circle } from '@cv/portal-rts-lib/geofences/models';

export const getConfigs = (country: string) => {
  const UoM = milesCountries.includes(country) ? 'MI' : 'KM';

  return {
    UoM: getLongUnit(UoM),
    min: distanceInUnits(GEOFENCE_RADIUS_MIN, UoM),
    max: distanceInUnits(GEOFENCE_RADIUS_MAX, UoM),
  };
};

const getMapUoM = (UoM: UpperCaseLongUnits, Maps: any) =>
  ({
    MILES: Maps.SpatialMath.DistanceUnits.Miles,
    KILOMETERS: Maps.SpatialMath.DistanceUnits.Kilometers,
  }[UoM]);

declare global {
  interface Window {
    Microsoft: any;
    makeMap: any;
    onBingMapsLoaded: () => void;
    bingMapsLoaded?: boolean;
  }
}

type PushPins = Array<pushPin>;

type pushPin = {
  center: Location;
  options?: any;
  click?: () => void;
  hover?: () => void;
  hoverOut?: () => void;
  metadata?: any;
  infoboxHtml?: any;
};

type Polylines = Array<Location>;

type ViewOptions = {
  bounds?: any;
  center?: any;
  centerOffset?: any;
  heading?: number;
  labelOverlay?: any;
  mapTypeId?: string;
  padding?: number;
  pitch?: number;
  zoom?: number;
  hideRoadLabels?: boolean;
};

interface Location {
  latitude: number | null;
  longitude: number | null;
}

type LocationRect = {
  center?: Location;
  height?: number;
  width?: number;
};

type MapOptions = {
  allowHidingLabelsOfRoad?: boolean;
  allowInfoboxOverflow?: boolean;
  backgroundColor?: string;
  credentials?: string;
  customMapStyle?: any;
  disableBirdseye?: boolean;
  disableKeyboardInput?: boolean;
  disableMapTypeSelectorMouseOver?: boolean;
  disablePanning?: boolean;
  disableScrollWheelZoom?: boolean;
  disableStreetside?: boolean;
  disableStreetsideAutoCoverage?: boolean;
  disableZooming?: boolean;
  enableClickableLogo?: boolean;
  enableCORS?: boolean;
  enableHighDpi?: boolean;
  enableInertia?: boolean;
  liteMode?: boolean;
  maxBounds?: LocationRect;
  maxZoom?: number;
  minZoom?: number;
  navigationBarMode?: any;
  navigationBarOrientation?: any;
  showBreadcrumb?: boolean;
  showDashboard?: boolean;
  showLocateMeButton?: boolean;
  showMapTypeSelector?: boolean;
  showScalebar?: boolean;
  showTrafficButton?: boolean;
  showTermsLink?: boolean;
  showZoomButtons?: boolean;
  streetsideOptions?: any;
  supportedMapTypes?: any;
};

type RouteSummary = {
  via: string;
  timeWithTraffic: string;
  distance: string;
};

type BoundaryLocation = {
  latitude: number;
  longitude: number;
};

type BingMapProps = {
  height?: string;
  mapOptions?: any;
  pushPins?: PushPins;
  polylines?: Polylines;
  polylineOptions?: any;
  pushPinsWithInfoboxes?: PushPins;
  viewOptions?: ViewOptions;
  width?: string;
  setMap(map: any): void;
  handleViewChange: (e: any) => void;
  directions: Array<string> | null;
  multipleBoundaryLocations: Array<BoundaryLocation>;
  boundaryLocation: BoundaryLocation;
  handleBoundaryChange: (value: number) => void;
  setRouteSummary: (routeSummary: RouteSummary) => void;
  unit: string;
  setManagerOfDirections?: (directionsManager: object) => void;
  boundaryColor: string | null;
  vehicleLocationClicked: boolean;
  boundaryRadius: Circle;
};

const BingMap = React.memo(
  ({
    height,
    mapOptions,
    pushPins,
    polylines,
    polylineOptions,
    pushPinsWithInfoboxes,
    viewOptions,
    width,
    setMap,
    handleViewChange,
    directions,
    boundaryLocation,
    multipleBoundaryLocations,
    handleBoundaryChange,
    setRouteSummary,
    unit,
    setManagerOfDirections,
    boundaryColor,
    vehicleLocationClicked,
    boundaryRadius,
  }: BingMapProps) => {
    // Inject Bing Maps scrip
    const country = useSelector(({ vehicleReducer }: RootState) => vehicleReducer.vehicle.registrationCountry);
    const { UoM: systemUoM, min, max } = getConfigs(country);
    const userLocation = useGeolocation();

    const locale = useLocaleSelector();
    const lang = locale.split('-')[0];
    const mapApiKey = config.get<string>('MAP_API_KEY') || '';
    const mapUrl = new URL('https://www.bing.com/api/maps/mapcontrol');
    mapUrl.searchParams.set('key', mapApiKey);
    mapUrl.searchParams.set('callback', 'onBingMapsLoaded');
    if (lang) {
      mapUrl.searchParams.set('setLang', lang);
    }
    useScript(mapUrl.toString());

    // refs
    const mapContainer = useRef(null);
    const map: any = useRef(null);
    const [directionsManager, setDirectionManager] = useState(null);
    const [bingMapsLoaded, setBingMapsLoaded] = useState(window.bingMapsLoaded);

    // Remove push pins
    const removePushpins = (map: any, Maps: any) => {
      for (let i = map.entities.getLength() - 1; i >= 0; i--) {
        const pushpin = map.entities.get(i);
        if (pushpin instanceof Maps.Pushpin) {
          map.entities.removeAt(i);
        }
      }
    };

    // Add pushpins with info boxes
    const addPushpinsWithInfoboxes = useCallback((pushPinsToAdd, infobox, map, Maps) => {
      removePushpins(map, Maps);
      pushPinsToAdd.forEach((pushPin: pushPin) => {
        const newPin = new Maps.Pushpin(pushPin.center, pushPin.options);
        newPin.metadata = pushPin.metadata;
        Maps.Events.addHandler(newPin, 'click', (e: any) => {
          infobox.setOptions({
            location: e.target.getLocation(),
            title: e.target.metadata.title,
            description: e.target.metadata.description,
            visible: true,
            htmlContent: pushPin.infoboxHtml,
          });
        });
        map.entities.push(newPin);
      });
    }, []);

    // Add polylines
    const addPolylines = (polylines: Polylines, polylineOptions: any, map: any, Maps: any) => {
      // Format into bing map location types
      const locations = polylines.reduce((newArray, polyline) => {
        const { latitude, longitude } = polyline;
        newArray.push(new Maps.Location(latitude, longitude));

        return newArray;
      }, []);

      const polylinesToAdd = new Maps.Polyline(locations, polylineOptions);
      map.entities.push(polylinesToAdd);

      // Add start and end pushpins for trip
      addPushpins([{ center: polylines[0] }, { center: polylines[polylines.length - 1] }], map, Maps);
    };

    // Add push pins
    const addPushpins = (pushPinsToAdd: PushPins, map: any, Maps: any) => {
      directionsManager && directionsManager.clearAll();
      removePushpins(map, Maps);
      if (pushPinsToAdd.length) {
        const locations = pushPinsToAdd.reduce((newArray: Array<Location>, pushPin) => {
          if (!pushPin.center) {
            return newArray;
          }
          const newPin = new Maps.Pushpin(pushPin.center, pushPin.options);

          if (pushPin.metadata) {
            const infobox = new Maps.Infobox(map.getCenter(), {
              visible: false,
            });
            infobox.setMap(map);
            Maps.Events.addHandler(newPin, 'click', (e: any) => {
              infobox.setOptions({
                offset: new Maps.Point(12, 100),
                location: e.target.getLocation(),
                title: pushPin.metadata.title,
                description: pushPin.metadata.description,
                visible: true,
              });
            });
          }

          if (pushPin.click) {
            Maps.Events.addHandler(newPin, 'click', pushPin.click);
          }

          if (pushPin.hover) {
            Maps.Events.addHandler(newPin, 'mouseover', (e) => {
              pushPin.hover();
              e.target.setOptions({ icon: carIcon });
            });
          }

          if (pushPin.hoverOut) {
            Maps.Events.addHandler(newPin, 'mouseout', (e) => {
              pushPin.hoverOut();
              e.target.setOptions({ icon: bluePin });
            });
          }
          newArray.push(pushPin.center);
          map.entities.push(newPin);

          return newArray;
        }, []);

        if (locations.length === 1) {
          const center = locations[0];
          map.setView({ center, zoom: 11 });
        } else {
          // Create bounds for to center map around push pins
          const rect = Maps.LocationRect.fromLocations(locations);
          map.setView({ bounds: rect, padding: 80 });
        }
      }
    };

    // Set map view options
    const setMapViewOptions = (map: any, viewOptions: ViewOptions, Maps: any) => {
      const options = { ...viewOptions };
      if (viewOptions.mapTypeId) {
        options.mapTypeId = Maps.MapTypeId[viewOptions.mapTypeId];
      }
      if (viewOptions.hideRoadLabels) {
        options.labelOverlay = Maps.LabelOverlay.hidden;
      }
      map.setView(options);
    };

    // Set map options
    const setMapOptions = (map: any, mapOptions: MapOptions, Maps: any) => {
      const options = { ...mapOptions };

      if (mapOptions.navigationBarMode) {
        options.navigationBarMode = Maps.NavigationBarMode[mapOptions.navigationBarMode];
      }
      if (mapOptions.navigationBarOrientation) {
        options.navigationBarOrientation = Maps.NavigationBarOrientation[mapOptions.navigationBarOrientation];
      }
      if (mapOptions.supportedMapTypes) {
        options.supportedMapTypes = mapOptions.supportedMapTypes.map((type: any) => Maps.MapTypeId[type]);
      }
      map.setOptions(options);
    };

    const setMultipleBoundaries = (map: any, Maps: any) => {
      clearMapBoundaries(map);

      const UoM = getMapUoM(systemUoM, Maps);

      if (multipleBoundaryLocations.length) {
        multipleBoundaryLocations.forEach((boundaryLocation: BoundaryLocation) => {
          //Create a polygon for the circle.
          const circle = new Maps.Polygon([boundaryLocation, boundaryLocation, boundaryLocation], {
            fillColor: 'transparent',
            strokeColor: boundaryColor || 'black',
            strokeThickness: 2,
          });

          //store the center point in the polygons metadata.
          circle.metadata = {
            center: boundaryLocation,
          };

          map.entities.push(circle);
          //Calculate circle locations.
          const locs = Maps.SpatialMath.getRegularPolygon(circle.metadata.center, min, 27, UoM);

          //Update the circles location.
          circle.setLocations(locs);
        });

        const box = multipleBoundaryLocations.map((loc: Location) => {
          return new Maps.Location(loc.latitude, loc.longitude);
        });

        if (multipleBoundaryLocations.length > 1) {
          const rect = Maps.LocationRect.fromLocations(box);
          !vehicleLocationClicked && map.setView({ bounds: rect });
        } else {
          const { latitude, longitude } = multipleBoundaryLocations[0];
          !vehicleLocationClicked && map.setView({ center: { latitude, longitude }, zoom: 10 });
        }
      }
    };

    const setBoundary = (map: any, Maps: any) => {
      clearMapBoundaries(map);
      const UoM = getMapUoM(systemUoM, Maps);

      const { latitude, longitude } = boundaryLocation;
      const offset = boundaryRadius?.value || min;

      const pins = [
        new Maps.Pushpin(new Maps.Location(latitude, longitude + (offset * 1.7) / 100), {
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"><circle cx="12.5" cy="12.5" r="10" stroke="black" stroke-width="2" fill="white" /></svg>',
          draggable: true,
        }),
      ];
      map.entities.push(pins);

      Maps.Events.addHandler(pins[0], 'drag', function (e: any) {
        UpdateCircle(e);
      });

      map.setView({ center: new Maps.Location(latitude, longitude), zoom: 12 });

      //Create a polygon for the circle.
      const circle = new Maps.Polygon([boundaryLocation, boundaryLocation, boundaryLocation], {
        fillColor: 'transparent',
        strokeColor: boundaryColor || 'black',
        strokeThickness: 2,
      });

      //store the center point in the polygons metadata.
      circle.metadata = {
        center: boundaryLocation,
      };

      map.entities.push(circle);
      //Calculate circle locations.
      const locs = Maps.SpatialMath.getRegularPolygon(circle.metadata.center, offset, 27, UoM);

      //Update the circles location.
      circle.setLocations(locs);

      const UpdateCircle = (e: any) => {
        //Calculate distance from circle center to mouse.
        const distance = Maps.SpatialMath.getDistanceTo(circle.metadata.center, e.location, UoM);

        const correctDistance = Math.max(min, Math.min(max, distance));

        //Calculate circle locations.
        const locs = Maps.SpatialMath.getRegularPolygon(circle.metadata.center, correctDistance, 36, UoM);

        circle.setLocations(locs);
        handleBoundaryChange(correctDistance);
      };
    };

    const onViewChange = (e: any) => {
      if (handleViewChange) {
        handleViewChange(e);
      }

      return () => {};
    };

    useEffect(() => {
      // Set distance unit
      directionsManager?.setRequestOptions({ distanceUnit: unit });
    }, [unit]);

    const setDirections = (map: any, Maps: any) => {
      removePushpins(map, Maps);

      // Create an itinerary container so Bing maps can render route details - we are hiding this and pulling the data
      directionsManager.setRenderOptions({
        itineraryContainer: document.getElementById('directionsItinerary'),
        waypointPushpinOptions: { draggable: false },
      });

      const addAddressToDirections = (address: string) => {
        if (!address) return;
        const waypoint = new Maps.Directions.Waypoint({
          address: address,
        });
        directionsManager.addWaypoint(waypoint);
      };

      directions?.map((point) => {
        addAddressToDirections(point);
      });

      //Calculate directions.
      directionsManager.calculateDirections();

      Maps.Events.addHandler(directionsManager, 'directionsUpdated', function (e: any) {
        const via = document.querySelectorAll("[data-tag='descriptionVia']");
        const departureTime = document.querySelectorAll("[data-tag='descriptionTimeNoTraffic']");

        const { distance, timeWithTraffic } = e.routeSummary[0];
        const routeSummary = {
          via: via[0].innerHTML,
          distance: `${distance.toFixed(2)} ${unit}`,
          timeWithTraffic,
          departureTime: departureTime[0]?.innerText,
        };
        setRouteSummary(routeSummary);
      });
    };

    // Make map and set pins, polylines, view options, map options, and handle view changes
    const makeMap = useCallback(() => {
      const { Maps } = window.Microsoft;

      if (!map.current) {
        map.current = new Maps.Map(mapContainer.current);
        Maps.loadModule('Microsoft.Maps.Directions', function () {
          //Create an instance of the directions manager.
          const manager = new Maps.Directions.DirectionsManager(map.current);
          setDirectionManager(manager);
          setManagerOfDirections?.(manager);
        });
        Maps.Events.addHandler(map.current, 'viewchangeend', onViewChange, 1000);
        setMap(map.current);
      }
      if (viewOptions) {
        setMapViewOptions(map.current, viewOptions, Maps);
      }

      if (mapOptions) {
        setMapOptions(map.current, mapOptions, Maps);
      }

      if (pushPins) {
        addPushpins(pushPins, map.current, Maps);
      }

      if (polylines && polylineOptions) {
        addPolylines(polylines, polylineOptions, map.current, Maps);
      }

      if (directions) {
        setDirections(map.current, Maps);
      }

      if (directions === null) {
        directionsManager && directionsManager.clearAll();
      }

      if (boundaryLocation) {
        Maps.loadModule('Microsoft.Maps.SpatialMath', () => {
          setBoundary(map.current, Maps);
        });
      }

      if (multipleBoundaryLocations) {
        Maps.loadModule('Microsoft.Maps.SpatialMath', () => {
          setMultipleBoundaries(map.current, Maps);
        });
      }

      if (pushPinsWithInfoboxes) {
        const infobox = new Maps.Infobox(map.current.getCenter(), {
          visible: false,
        });
        infobox.setMap(map.current);
        addPushpinsWithInfoboxes(pushPinsWithInfoboxes, infobox, map.current, Maps);
      }
      if (userLocation && userLocation.latitude && userLocation.longitude) {
        const { latitude, longitude } = userLocation;
        const center = new Maps.Location(latitude, longitude);
        const ableToSetUserLocation =
          !pushPinsWithInfoboxes && !pushPins?.length && !boundaryLocation && !multipleBoundaryLocations;
        if (ableToSetUserLocation) {
          map.current.setView({ center, zoom: 16 });
        }
      }
    }, [
      mapOptions,
      viewOptions,
      pushPins,
      pushPinsWithInfoboxes,
      addPushpinsWithInfoboxes,
      polylines,
      polylineOptions,
      directions,
      boundaryLocation,
      multipleBoundaryLocations,
      userLocation,
    ]);

    const onBingMapsLoaded = () => {
      // we need both so that the map will re-render correctly
      // when exited and re-entered
      window.bingMapsLoaded = true;
      setBingMapsLoaded(true);
    };

    window.onBingMapsLoaded = onBingMapsLoaded;

    useEffect(() => {
      if (bingMapsLoaded) {
        makeMap();
      }
    }, [makeMap, bingMapsLoaded]);

    // Can not set default props with React.memo
    // Setting default values here
    const mapHeight = height || '100%';
    const mapWidth = width || '100%';
    return <div className="map" ref={mapContainer} style={{ height: mapHeight, width: mapWidth }}></div>;
  },
);

export default BingMap;
