import React, { useEffect, useState } from 'react';
import { hasBillingInformation } from '../utils/addressFormUtils';
import { buildPreviewOrderRequestInfo, buildSelectedPackageSummary } from '../builders';
import { DiscountInfo, OrderSummaryInfo } from '../Types';
import { PreviewOrderResponse } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { useQueryClient } from '@tanstack/react-query';
import { getPromoCodePackage, hasPackageAdded, hasCorrectFullName } from '../utils';
import {
  useFlowLocation,
  useFlowMachine,
  useFlowMachineContext,
  useFlowMachineContextFlowStorage,
} from '../../Router/RouterContext';
import { FlowEventName } from '../../Router/flowTypes';
import { ActionTypes } from '../../Router/flowSessionStorage';
import PaymentWrapper from './Payment';
import { PaymentInfo } from '../../payment/Types';
import { useSelector } from '@xstate/react';
import { AddressForm } from './AddressForm';

export type PaymentWrapperProps = {
  allowEditName?: boolean;
};

const PaymentWrapperContainer = ({ allowEditName = true }: PaymentWrapperProps) => {
  const queryCache = useQueryClient();
  const flowMachine = useFlowMachine();
  const { send } = flowMachine;
  const { subscriptionProps, content } = useFlowMachineContext();
  const location = useFlowLocation();
  const setSubscriptionProps = (data) => {
    send({
      type: FlowEventName.SET_SUBSCRIPTION_PROPS,
      data,
    });
  };
  const {
    commonWebContent: { shouldIncludeTax },
  } = content;

  const { packageSubscriptions, discountInfo, subscribedPackages } = useFlowMachineContextFlowStorage();
  const [orderSummaryInfo, setOrderSummaryInfo] = useState<OrderSummaryInfo>(null);
  const [previewOrderResponse, setPreviewOrderResponse] = useState<PreviewOrderResponse>(null);
  const [previewOrderError, setPreviewOrderError] = useState<string>(null);

  const [displayPayment, setDisplayPayment] = useState<boolean>(() => {
    const hasCorrectBilling = hasBillingInformation(subscriptionProps.userDetails.billingAddress);
    return allowEditName ? hasCorrectBilling && hasCorrectFullName(subscriptionProps.userDetails) : hasCorrectBilling;
  });
  const promoCodePackages = useSelector(flowMachine, (state) => state.context.promoCodePackages);

  useEffect(() => {
    if (previewOrderResponse) {
      setOrderSummaryInfo(buildSelectedPackageSummary(packageSubscriptions, previewOrderResponse, shouldIncludeTax));
    }
  }, [previewOrderResponse]);

  useEffect(() => {
    if (previewOrderError) {
      send({
        type: FlowEventName.SET_SESSION_STORAGE,
        action: { type: ActionTypes.RemoveDiscountInfo, payload: null },
      });
    }
  }, [previewOrderError]);

  const invokePreviewOrder = () => {
    queryCache.clear();
    setPreviewOrderError('');
  };

  const getPreviewOrderError = () => {
    return previewOrderError;
  };

  const getSubtotal = () => {
    const totalAmount = Number(orderSummaryInfo?.amountTotal);
    const { totalDiscountAmount } = getPromoCodePackage(orderSummaryInfo?.packages, discountInfo?.promoCode);
    return (totalAmount - totalDiscountAmount).toFixed(2);
  };

  const navigateCancel = () => send({ type: FlowEventName.NAVIGATE_BACK });
  const submitCallback = ({ paymentMethodId, paymentOption }) => {
    send({
      type: FlowEventName.SET_SESSION_STORAGE,
      action: {
        type: ActionTypes.AddPaymentMethodId,
        payload: { paymentMethodId } as PaymentInfo,
      },
    });
    send({
      type: FlowEventName.SET_SESSION_STORAGE,
      action: {
        type: ActionTypes.AddPaymentOption,
        payload: { paymentOption } as PaymentInfo,
      },
    });
    send({ type: FlowEventName.NAVIGATE_FORWARD });
  };

  const addDiscountInfo = (discountInfo: DiscountInfo) => {
    send({
      type: FlowEventName.SET_SESSION_STORAGE,
      action: { type: ActionTypes.AddDiscountInfo, payload: discountInfo },
    });
  };

  const previewOrderRequestInfo = buildPreviewOrderRequestInfo(packageSubscriptions, subscribedPackages, discountInfo);

  const canEnterPromoCode = () => {
    const { discountCriteria } = subscriptionProps;
    return !(discountCriteria && promoCodePackages?.some((pkg) => hasPackageAdded(packageSubscriptions, pkg)));
  };

  return (
    <PaymentWrapper
      invokePreviewOrder={invokePreviewOrder}
      getPreviewOrderError={getPreviewOrderError}
      getSubtotal={getSubtotal}
      setPreviewOrderResponse={setPreviewOrderResponse}
      setPreviewOrderError={setPreviewOrderError}
      navigateCancel={navigateCancel}
      submitCallback={submitCallback}
      addDiscountInfo={addDiscountInfo}
      subscriptionProps={subscriptionProps}
      displayPayment={displayPayment}
      discountInfo={discountInfo}
      orderSummaryInfo={orderSummaryInfo}
      content={content}
      location={location}
      canEnterPromoCode={canEnterPromoCode()}
      previewOrderRequestInfo={previewOrderRequestInfo}
    >
      <AddressForm
        allowEditName={allowEditName}
        subscriptionProps={subscriptionProps}
        setSubscriptionProps={setSubscriptionProps}
        content={content}
        setDisplayPayment={setDisplayPayment}
      />
    </PaymentWrapper>
  );
};

export default PaymentWrapperContainer;
