import { ISxmKey } from '@api/types';
import { getOemValue } from '@config/config';
import { PromoCodeCriteria } from '@redux/reducers/user';
import { isEmpty, assign } from 'lodash';

// We keep it to save data saved to memory
// for faster getting this data and also
// to prevent asking session storage for this data
export const STORE_NAME = 'searchParams';
let params = {};

const getFlowParams = () => {
  const queryParams = getOemValue('QUERY_PARAMS').split(',').filter(Boolean);
  const additionalQueryParams = getOemValue('QUERY_PARAMS_ADDITIONAL').split(',').filter(Boolean);
  const paramsToKeep = getOemValue('SAVE_QUERY_PARAMS').split(',').filter(Boolean);

  const combinedParams = Array.from(new Set(queryParams.concat(additionalQueryParams)));

  return [combinedParams, paramsToKeep];
};

// TODO: maybe we need to have caching functionality here?
const extractSearchParams = (
  search: string,
  flowsParams: string[],
  paramsToKeep: string[] = [],
): [Record<string, string>, string] => {
  const searchParams = new URLSearchParams(search);
  const searchParamsKeys = [...searchParams.keys()].reduce<Record<string, string>>(
    (acc, key) => ({ ...acc, [key.toLowerCase()]: key }),
    {},
  );

  const lowerCaseParamsToKeep = new Set(paramsToKeep.map((param) => param.toLowerCase()));

  const foundParams: Record<string, string> = {};

  for (const param of flowsParams) {
    const [key, name] = param.split(':');
    const lowerCaseKey = key.toLowerCase();
    const keyToGet = searchParamsKeys[lowerCaseKey];

    const value = searchParams.get(keyToGet);

    if (value !== null) {
      foundParams[name ?? lowerCaseKey] = value;

      if (!lowerCaseParamsToKeep.has(lowerCaseKey)) {
        searchParams.delete(keyToGet);
      }
    }
  }

  return [foundParams, searchParams.toString()];
};

export const parseSearchParams = (search: string) => {
  const [flowsParams, paramsToKeep] = getFlowParams();
  return extractSearchParams(search, flowsParams, paramsToKeep);
};

export type FlowMetaValuesType = {
  flowName: string;
  uri: string;
  sxmKeyDetails: ISxmKey;
  userEmail: string;
};

const getStoredParams = () => {
  const storedParams = sessionStorage.getItem(STORE_NAME);
  if (storedParams) {
    return JSON.parse(storedParams);
  }

  return params;
};

const getAll = <T extends Record<string, unknown>>() => {
  if (isEmpty(params)) {
    params = getStoredParams();
  }

  // it is possible to put some value that are not related to defined params
  // so we need to have an ability of getting this data correctly
  return params as T;
};

const get = <T>(paramName: string): T => {
  const params = getAll();

  return params[paramName] as T;
};

const setMultiple = <T extends Record<string, unknown>>(newParams: T): boolean => {
  params = assign(getAll(), newParams);
  sessionStorage.setItem(STORE_NAME, JSON.stringify(params));
  return true;
};

const set = <V>(key: string, value: V): boolean => {
  return setMultiple({ [key]: value });
};

export const clearParams = () => {
  params = {};
  sessionStorage.removeItem(STORE_NAME);
};

export const parsePromoCodeCriteria = (str: string): PromoCodeCriteria | null => {
  const regExp = new RegExp(/^(\d+)-(\d+)-(\d+)$/g);

  const match = regExp.exec(str);

  if (match) {
    const values = match.slice(1).map((strValue) => Math.trunc(Number(strValue)));
    const [pkgTier, term, pkgCount] = values;
    return {
      pkgTier,
      term,
      pkgCount,
    };
  }

  return null;
};

export default {
  extractSearchParams,
  getFlowParams,
  getAll,
  get,
  setMultiple,
  set,
};
