import React from 'react';
import { Modal } from '@components-lib';
import { LoaderBackdrop } from '@components/Loader';
import useRemoteConfig from '@api/queries/useRemoteConfig';
import snakeCase from 'lodash/snakeCase';
import * as envs from '../../config/envs';
import { Environment } from '@cv/portal-common-lib/ajax/constants';

const ConfigContext = React.createContext(null);

export const ConfigProvider: React.FC = ({ children }) => {
  const { isError, isLoading, isSuccess, data: config } = useRemoteConfig();

  global.CONFIG = config;

  return (
    <ConfigContext.Provider value={config}>
      {isLoading && <LoaderBackdrop />}
      {isSuccess && children}
      <Modal open={isError} title="Configuration Load Error" showXButton={false}>
        We're sorry, but it seems that there was an issue loading the configuration settings for the application.
      </Modal>
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const config = React.useContext(ConfigContext);
  if (!config) {
    throw new Error('useConfig should be used within ConfigContext');
  }
  const get = <T extends unknown>(key: string, defaultValue: T | null = null): T | null => {
    if (key in config) {
      return config[key] as T;
    }

    const env = `${config.ENV?.toLowerCase()}Config` as keyof typeof envs;
    if (env in envs && key in envs[env]) {
      return envs[env][key as keyof (typeof envs)[typeof env]] as T;
    }

    return defaultValue;
  };
  const getOemName = (): string => {
    const { host } = window.location;
    const key = `CONTENTFUL_TAG_${snakeCase(host).toUpperCase()}`;
    return get<string>(key, get<string>('CONTENTFUL_TAG_UNKNOWN')) || '';
  };
  const getOemValue = (key: string) => get<string>(`${getOemName().trim()}_${key}`) || get<string>(key) || '';

  const getEnvironmentEnum = () => {
    const serviceEnv = get<keyof typeof Environment>('SERVICE_ENVIRONMENT') || Environment.LOCAL;
    const env = Environment[serviceEnv];
    if (env !== undefined) {
      return env;
    }
    return Environment.LOCAL;
  };
  return {
    get,
    getOemName,
    getOemValue,
    getEnvironmentEnum,
  };
};
