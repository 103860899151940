import React, { useState } from 'react';

import styles from './InputRange.module.css';

type InputRangeProps = {
  value: number;
  min: number;
  max: number;
  step: number;
  disabled?: boolean;
  label?: string;
  labelUnit?: string;
  unit?: string;
  hint?: string;
  showMinMaxValues?: boolean;
  onChange: (value: number) => void;
  className?: string;
};
export default function InputRange({
  value = 0,
  min = 0,
  max = 100,
  step = 10,
  disabled = false,
  label = '',
  labelUnit = '',
  unit = '',
  hint = '',
  showMinMaxValues = false,
  onChange,
  className,
}: InputRangeProps) {
  const [currentValue, setCurrentValue] = useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = Number(event.target.value);

    setCurrentValue(currentValue);
    onChange(currentValue);
  };

  const renderValues = (value: number) => {
    return <h6 className={styles['input-range-label-value']}>{`${value} ${unit ? unit : ''}`}</h6>;
  };

  return (
    <div className={`${styles['input-range-container']} ${className}`}>
      {label && (
        <div className={styles['input-range-label-wrapper']}>
          <h5 className={styles['input-range-label']}>{label}</h5>
          <h5 className={styles['input-range-label-value']}>{`${currentValue} ${labelUnit || unit || ''}`}</h5>
        </div>
      )}
      <div className={styles['input-range-wrapper']}>
        {showMinMaxValues && renderValues(min)}
        <div className={styles['input-range-inner']}>
          <input
            className={styles['input-range']}
            type="range"
            value={currentValue}
            min={min}
            max={max}
            step={step}
            disabled={disabled}
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={value}
            onChange={handleChange}
          />
        </div>
        {showMinMaxValues && renderValues(max)}
      </div>
      {hint && <h6 className={styles['input-range-hint']}>{hint}</h6>}
    </div>
  );
}
