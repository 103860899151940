import CryptoJS from 'crypto-js';
import config from '@config/config';
import milliseconds from 'date-fns/milliseconds';
import { History } from 'history';

import { Api } from '@api';
import { getCookie, setCookie } from '@utils/cookies';
import urlSearchParams from '@utils/urlSearchParams';

const base64URLEncode = (words: CryptoJS.lib.WordArray): string =>
  CryptoJS.enc.Base64.stringify(words)?.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');

export const login = (): void => {
  const verifier = base64URLEncode(CryptoJS.lib.WordArray.random(50));
  const challenge = base64URLEncode(CryptoJS.SHA256(verifier));
  const stateCode = CryptoJS.lib.WordArray.random(6).toString();

  setCookie('verifier', verifier);
  setCookie('stateCode', stateCode);

  const ssoType = urlSearchParams.get<string>('idp');
  const params = new URLSearchParams({
    response_type: config.getOemValue('LOGIN_TYPE'),
    client_id: config.getOemValueBySuffix('LOGIN_CLIENT_ID', ssoType),
    redirect_uri: config.getOemValue('LOGIN_REDIRECT'),
    scope: config.getOemValue('LOGIN_SCOPE'),
    code_challenge: challenge,
    code_challenge_method: 'S256',
    state: stateCode,
  });

  const realm = config.getOemValue('LOGIN_REALM');
  if (realm) {
    params.set('realm', realm);
  }

  const uri = `${config.getOemValueBySuffix('LOGIN_URL', ssoType)}?${params.toString()}`;

  /**
   * This part is related to possible issue with firefox:
   * https://bugzilla.mozilla.org/show_bug.cgi?id=1536797
   *
   * We are trying to check if localStorage is set properly before we redirect user
   * to the next page.
   *
   * was done by ticket: https://jira.atxg.com/browse/PORTAL-868
   */
  let intervalCleared = false;
  const interval = setInterval(() => {
    clearInterval(interval);
    intervalCleared = true;
    window.location.replace(uri);
  }, 100);

  setTimeout(() => {
    if (!intervalCleared) {
      clearInterval(interval);
      throw new Error('There is some issue with browser cookies');
    }
  }, milliseconds({ seconds: 10 }));
};

export const loginWithCallback = (history: History, api: Api) => async (): Promise<void> => {
  const historyLocationSearch = new URLSearchParams(history.location.search);
  const code = historyLocationSearch.get('code') || '';
  const returnedStateCode = historyLocationSearch.get('state') || '';
  const verifier = getCookie('verifier') || '';
  const stateCode = getCookie('stateCode') || '';

  if (stateCode !== returnedStateCode) {
    throw new Error('sxm state code error');
  }

  if (!verifier) {
    throw new Error('verifier cookie not set');
  }

  const isExternalIdp = config.getOemValue('SXM_ACCESS_TOKEN_URL');

  const response = await api.getIdmAccessToken({ code, verifier, stateCode });

  const exchangeToken = response?.data?.id_token;
  if (!exchangeToken) {
    throw new Error('exchangeToken is missing');
  }

  let tokenResponse = response.data;

  if (isExternalIdp) {
    tokenResponse = await api.getAccessToken({ exchangeToken });
  }

  api.storeTokenResponse(tokenResponse);
};
