import React from 'react';

import ContentRenderer from '@components/ContentRenderer';
import MapWrapper from '@components/Map/MapWrapper';
import { GlobalTheme } from '@components/Theme';
import { HeaderProps } from '@components/Header';
import config from '@config/config';

import './App.css';
import { BrandNamesFilterGroup } from '@customTypes/BrandNamesFilterGroup';
import { GlobalPreferencesContent } from '@components/GlobalPreferences/GlobalPreferences';
import { useNavigation } from '@components/Navigation';
import { Page } from '@customTypes/Navigation';
import AnalyticsTrackView from '@components/Analytics';
import { Location, History } from 'history';
import ContentfulComponent from '@customTypes/ContentfulComponent';
import AppWrapperErrorDialog from './AppWrapperErrorDialog';
import { ContentType } from 'contentful';
import { ServerLabel } from '@utils/labels';

export type MapOverlayContainer = {
  name: string;
  location: 'home' | 'remote' | 'destinations' | 'monitor';
  contentList: ContentfulComponent[];
  apiConnector: ContentfulComponent;
};

export type PageContent = {
  theme?: GlobalTheme[];
  header?: HeaderProps['content'][];
  mapOverlayContainer?: Array<MapOverlayContainer>;
  contentSections?: Array<ContentfulComponent>;
  footerContent?: Array<ContentfulComponent>;
  footerFilter?: BrandNamesFilterGroup;
  pagesList?: Array<Page>;
  globalPreferences?: GlobalPreferencesContent[];
  navigationMachine?: {
    id: string;
    initial: string;
    initialUnauth: string;
  };
  unauthorizedPages?: Array<Page>;
  apiErrors?: ContentType;
  fallbackPages?: Array<ServerLabel>;
  unauthFallbackPages?: Array<ServerLabel>;
};

export type AppWrapperProps = {
  pageContent?: PageContent;
  history: History;
  location: Location;
};

const ContentContext = React.createContext({});
export const getContentContext = () => React.useContext(ContentContext);

const PAGES_WITH_MAP = ['remote', 'home', 'monitor', 'destinations'];

const AppWrapper = React.memo(({ pageContent = {} }: AppWrapperProps) => {
  const { mapOverlayContainer = [] } = pageContent;
  const isMapEnabled = config.get<boolean>('mapEnabled', true);
  const { currentPath } = useNavigation();

  const renderPage = () => {
    const { details } = currentPath;
    if (!details) {
      return null;
    }
    return (
      <React.Fragment key={details.path}>
        {details.services?.length > 0 && (
          <AppWrapperErrorDialog services={details.services} modalContent={details.modalContent} />
        )}
        <AnalyticsTrackView analyticsEventName={details.analyticsEventName}>
          <ContentRenderer
            name={details.name}
            content={details.contentSections as ContentfulComponent[]}
            key={details.name}
          />
        </AnalyticsTrackView>
      </React.Fragment>
    );
  };

  const renderMapWrapper = () => {
    const visible = PAGES_WITH_MAP.includes(currentPath.page);
    const isMapDisabledInContentful = currentPath?.details?.disableMapRendering ?? true;
    return isMapEnabled && visible && !isMapDisabledInContentful ? <MapWrapper /> : null;
  };

  return (
    <ContentContext.Provider value={mapOverlayContainer}>
      {renderMapWrapper()}
      {renderPage()}
    </ContentContext.Provider>
  );
});

export default AppWrapper;
