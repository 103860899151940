// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader-backdrop {
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0.6;
  background-color: #fff;
  z-index: var(--theme-z-index-loader);
}
`, "",{"version":3,"sources":["webpack://./src/components/Loader/LoaderBackdrop.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,MAAM;EACN,OAAO;EACP,QAAQ;EACR,YAAY;EACZ,sBAAsB;EACtB,oCAAoC;AACtC","sourcesContent":[".Loader-backdrop {\n  position: fixed;\n  display: flex;\n  justify-content: center;\n  bottom: 0;\n  top: 0;\n  left: 0;\n  right: 0;\n  opacity: 0.6;\n  background-color: #fff;\n  z-index: var(--theme-z-index-loader);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
