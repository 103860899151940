import { EligiblePackageInfo, PackageSubscription, SubscribedPackageInfo } from '../Types';
import { isPaidPackage, isTermMatch } from './packageUtils';
import { Product } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';

export const determinePackageOrBundle = (
  eligiblePackages: EligiblePackageInfo[],
  packageSubscriptions: PackageSubscription[],
  subscribedPackages: SubscribedPackageInfo[],
  selectedPkg: EligiblePackageInfo,
) => {
  const matchingBundle = getMatchingProductBundle(eligiblePackages, selectedPkg);
  const isAddProductsSubscribed = isAllProductsFromBundleSubscribed(
    matchingBundle,
    eligiblePackages,
    packageSubscriptions,
    subscribedPackages,
    selectedPkg,
  );
  return isAddProductsSubscribed ? matchingBundle : selectedPkg;
};

export const findPackageByProduct = (eligiblePackages: EligiblePackageInfo[], productId: string, isBundle: boolean) => {
  return eligiblePackages?.find(
    ({ products, bundle, variant }) =>
      bundle === isBundle && containsProduct(products, productId) && isPaidPackage(variant),
  );
};

export const getProductMatchingBundle = (
  packagesSubscriptions: PackageSubscription[],
  packageInfo: EligiblePackageInfo | SubscribedPackageInfo,
) => {
  const eligibleBundles = packagesSubscriptions?.filter(({ bundle }) => bundle);
  const productByPackageName = findProductByPackageName(packageInfo);

  if (!eligibleBundles?.length || !productByPackageName || productByPackageName.overlapAllowed) {
    return null;
  }
  return eligibleBundles?.find(
    ({ products, variant }) =>
      containsProduct(products, productByPackageName.id) &&
      isPaidPackage(variant) &&
      isPaidPackage(packageInfo.variant),
  );
};

export const getMatchingProductBundle = (ePackages: EligiblePackageInfo[], selectedPkg: EligiblePackageInfo) => {
  const eligibleBundles = ePackages?.filter(({ bundle }) => bundle);
  const productByPackageName = findProductByPackageName(selectedPkg);

  if (!eligibleBundles?.length || !productByPackageName) {
    return null;
  }
  return findPackageByProduct(eligibleBundles, productByPackageName.id, true);
};

export const doesBundleContainPackage = (
  subscribedPackage: SubscribedPackageInfo,
  selectedPkg: PackageSubscription,
) => {
  const productByPackageName = findProductByPackageName(selectedPkg);
  if (!subscribedPackage?.bundle || !productByPackageName || productByPackageName.overlapAllowed) {
    return false;
  }
  return containsProduct(subscribedPackage.products, productByPackageName.id);
};

const findProductByPackageName = ({ packageName, products }: EligiblePackageInfo | SubscribedPackageInfo) =>
  products.find((p) => packageName.includes(p.productName));

const containsProduct = (products: Product[], productId: string) => products.some(({ id }) => productId === id);

export const isAllProductsFromBundleSubscribed = (
  eBundle: EligiblePackageInfo,
  eligiblePackages: EligiblePackageInfo[],
  packagesSubscriptions: PackageSubscription[],
  subscribedPackages: SubscribedPackageInfo[],
  selectedPkg: EligiblePackageInfo,
) => {
  const paidProducts = getPaidProducts(eligiblePackages, eBundle);
  if (!paidProducts?.length) {
    return false;
  }
  const { products: selectedProducts, variant: selVariant } = selectedPkg;
  const { initialTerm: vTerm, initialTermUnit: vTermUnit } = selVariant;
  return paidProducts.every(({ id }) => {
    return (
      containsProduct(selectedProducts, id) ||
      packagesSubscriptions.some(
        ({ products, variant }) => containsProduct(products, id) && isTermMatch(variant, vTermUnit, vTerm),
      ) ||
      subscribedPackages.some(
        ({ products, bundle, variant: { initialTerm, initialTermUnit } }) =>
          containsProduct(products, id) && !bundle && isTermMatch(selVariant, initialTermUnit, initialTerm),
      )
    );
  });
};

const getPaidProducts = (ePackages: EligiblePackageInfo[], eBundle: EligiblePackageInfo) => {
  return eBundle?.products.filter(({ id, overlapAllowed }) => {
    return (
      !overlapAllowed &&
      ePackages.some(({ products, variant }) => containsProduct(products, id) && isPaidPackage(variant))
    );
  });
};
